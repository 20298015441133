import * as actions from 'state/actions';
import { put, select, take } from 'redux-saga/effects';
import standalone_config from 'state/standalone/config.json';
import * as helper from './sagaHelper.js';
import CONST from 'constants.js';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { channel } from 'redux-saga';
const drawControl = new MapboxDraw();

const bridge = channel();
export function* watchBridge() {
  while (true) {
    const action = yield take(bridge);
    yield put(action);
  }
}

const onCreate = (e) => {
  const data = e.features[0];
  bridge.put({
    type: actions.APP_UPDATE_DRAWING,
    payload: { id: data.id, data }
  });
};
const onUpdate = (e) => {
  const data = e.features[0];
  bridge.put({
    type: actions.APP_UPDATE_DRAWING,
    payload: { id: data.id, data }
  });
};
const onDelete = (e) => {
  const data = e.features[0];
  bridge.put({
    type: actions.APP_UPDATE_DRAWING,
    payload: { id: data.id, data: null }
  });
};

export const disableMapDraw = function* (action) {
  if (action.payload !== CONST.UI_MODE.MAP_DRAWING) {
    const map = yield select((reduxState) => reduxState.app.map);
    if (map) {
      map.off('draw.create', onCreate);
      map.off('draw.update', onUpdate);
      map.off('draw.delete', onDelete);
      if (map.draw) map.removeControl(drawControl, 'bottom-left');
    }
  }
};

export const enableMapDraw = function* (action) {
  if (action.payload === CONST.UI_MODE.MAP_DRAWING) {
    const map = yield select((reduxState) => reduxState.app.map);
    if (map) {
      if (!map.draw) map.draw = drawControl;
      map.on('draw.create', onCreate);
      map.on('draw.update', onUpdate);
      map.on('draw.delete', onDelete);
      map.addControl(drawControl, 'top-left');
    }
  }
};

export const recordGlobalPrefs = function* (action) {
  const token = yield select((reduxState) => reduxState.user.token);
  const maps = yield select((reduxState) => reduxState.app.available_maps);
  const prefs = yield select((reduxState) => reduxState.app.prefs);

  for (let idx = 0; idx < maps.length; idx++) {
    const response = yield helper.signedAPI(
      'GET',
      `s_config?id=eq.${maps[idx].id}`,
      token
    );
    const response_json = yield response.json();
    const thesePrefs = response_json[0].preferences;
    let preferences = {};
    Object.keys(thesePrefs).forEach((prefKey) => {
      preferences[prefKey] = action.payload.includes(prefKey)
        ? prefs[prefKey].value
        : thesePrefs[prefKey];
    });
    const endpoint = `s_config?id=eq.${maps[idx].id}`;
    yield put({
      type: actions.API_PERFORM_CRUD,
      payload: {
        method: 'PATCH',
        endpoint,
        data: {
          preferences
        },
        type: action.type
      }
    });
  }
};

export const uiShow = function* (action) {
  const prefs = yield select((reduxState) => reduxState.app.prefs);
  if (action.payload === 'inspector') {
    if (prefs.poi_inspector.value === 'media') {
      yield put({
        type: actions.UI_SHOW,
        payload: CONST.UI.MEDIA_INSPECTOR
      });
    } else {
      yield put({
        type: actions.UI_SHOW,
        payload: CONST.UI.POI_INSPECTOR
      });
    }
  }
};

export const recordPrefChanges = function* (action) {
  const prefs = yield select((reduxState) => reduxState.app.prefs);
  const mapId = yield select((reduxState) => reduxState.app.id);
  let preferences = {};
  Object.keys(prefs).forEach(
    (prefKey) => (preferences[prefKey] = prefs[prefKey].value)
  );
  yield put({
    type: actions.API_PERFORM_CRUD,
    payload: {
      method: 'PATCH',
      endpoint: `s_config?id=eq.${mapId}`,
      data: {
        title: prefs.title.value,
        uuid: prefs.uuid.value,
        is_published: prefs.isPublished.value,
        preferences
      },
      type: action.type
    }
  });
};

export const initialize = function* (action) {
  switch (window._env_.REACT_APP_MODE) {
    case CONST.APP_MODE.DEMO:
    case CONST.APP_MODE.STANDALONE:
      yield put({
        type: actions.APP_INITIALIZATION_RECEIVED,
        payload: standalone_config
      });
      yield put({
        type: actions.MEDIA_GET_CONFIG
      });
      break;

    case CONST.APP_MODE.API:
    default:
      try {
        const mapId = action.payload.map_id;
        let response;
        let authToken = localStorage.getItem('authtoken');
        if (authToken !== null) {
          authToken = authToken.replace(/['"]+/g, '');
          response = yield helper.signedAPI(
            'GET',
            `s_config?id=eq.${mapId}`,
            authToken
          );
          if (!response.ok) {
            yield localStorage.removeItem('authtoken');
            yield put({
              type: actions.APP_INITIALIZE,
              payload: { map_id: mapId }
            });
          }
        } else {
          response = yield helper.anonymousAPI('GET', `config?id=eq.${mapId}`);
        }
        let response_json = yield response.json();
        if (response_json.length === 0 && mapId > 1) {
          yield put({
            type: actions.APP_INITIALIZE,
            payload: { map_id: 1 }
          });
        } else {
          if (response.ok) {
            if (response_json.length > 0) {
              yield put({ type: actions.APP_LOCK_MAP });

              yield put({
                type: actions.APP_INITIALIZATION_RECEIVED,
                payload: response_json
              });
              yield put({
                type: actions.MEDIA_GET_CONFIG,
                payload: mapId
              });
              yield put({
                type: actions.MEDIA_GET,
                payload: mapId
              });
            } else {
              yield put({
                type: actions.APP_EMPTY_INITIALIZATION_RECEIVED,
                payload: response_json
              });
            }
          } else {
            yield put({
              type: actions.APP_EMPTY_INITIALIZATION_RECEIVED,
              payload: response_json
            });
            yield put({
              type: actions.APP_SAGA_ERROR,
              payload: {
                source: action.type,
                error: {
                  message: `CODE: ${response.status} ${JSON.stringify(
                    response_json
                  )}`
                }
              }
            });
          }
        }
      } catch (e) {
        yield put({
          type: actions.APP_SAGA_ERROR,
          payload: {
            error: e,
            source: action.type
          }
        });
      }
      break;
  }
};

export const sagaError = function* (action) {
  yield put({ type: actions.UI_SHOW_FATAL_ERROR, payload: action.payload });
  console.log(action);
  yield console.error(
    `😫 SAGA FATAL (${JSON.stringify(action.payload.source)}): ${
      action.payload.error
        ? JSON.stringify(action.payload.error.response)
        : 'UNKNOWN'
    }`
  );
};

export const sagaErrorNonfatal = function* (action) {
  yield console.warn(
    `😫 SAGA NONFATAL ERROR (${action.payload.source}): ${
      action.payload.error ? action.payload.error : 'UNKNOWN'
    }`
  );
};

export const log = (effect, action) => {
  console.warn(
    `😫 SAGA LOG (${action.payload.source}): ${
      action.payload.error ? action.payload.error : 'UNKNOWN'
    }`
  );
  return effect;
};

export const unpinAll = function* () {
  yield put({ type: actions.APP_PIN_TIMEFILTER, payload: false });
  yield put({ type: actions.APP_PIN_SEARCH, payload: false });
};

export const hideAll = function* () {
  yield put({ type: actions.APP_VISIBILITY_AUDIOTOUR, payload: false });
  yield put({ type: actions.APP_VISIBILITY_SEARCH, payload: false });
  yield put({ type: actions.APP_VISIBILITY_TIMEFILTER, payload: false });
  yield put({ type: actions.APP_VISIBILITY_SPINNER, payload: false });
};
