import { combineReducers } from 'redux';
import app from './app';
import ui from './ui';
import audioTour from './audioTour';
import inspector from './inspector';
import media from './media';
import server from './server';

import search from './search';
import spinner from './spinner';
import timeFilter from './timeFilter';
import user from './user';
import poi from './poi';
import proximity from './proximity';
import packager from './packager';
const rootReducer = combineReducers({
  app,
  ui,
  search,
  user,
  media,
  server,
  audioTour,
  inspector,
  timeFilter,

  spinner,
  poi,
  proximity,
  packager
});

export default rootReducer;
