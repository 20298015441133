import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './ImagePreview.module.css';
import { Modal } from 'components';

class ImagePreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zoom: false,
      isLoaded: false,
      isLoaded_zoom: false,
      rotation: 0
    };
  }

  componentDidUpdate = (previousProp) => {
    if (this.props.src !== previousProp.src) {
      this.setState({ isLoaded: false, isLoaded_zoom: false });
      console.log('Loading');
      this.notFound = setTimeout(function () {
        console.log('Hello');
      }, 3000);
    }
  };

  render() {
    let placeholder =
      this.props.src && this.props.src.length > 0 ? (
        <div
          className={styles.spinner}
          style={!this.state.isLoaded_zoom ? {} : { display: 'none' }}
        >
          <div className={styles.doublebounce1} />
          <div className={styles.doublebounce2} />
        </div>
      ) : (
        <div className={styles.missing} />
      );
    return (
      <div className={styles.container} onClick={this.openModal}>
        {this.state.zoom && (
          <Modal
            scrollable={false}
            backgroundColor="rgba(0, 0, 0, 0.74)"
            naturalWidth={true}
            onOutsideClick={this.closeModal}
          >
            {!this.state.isLoaded && placeholder}
            <img
              alt={this.props.src}
              key={this.props.id}
              id={`previewImage_${this.props.id}`}
              className={styles.image}
              style={
                this.state.isLoaded
                  ? {
                      height: '90vh',
                      objectFit: 'contain',
                      transform: `rotate(${this.state.rotation}deg)`
                    }
                  : { display: 'none' }
              }
              onLoad={this.onImageLoad}
              src={this.props.src}
            />
          </Modal>
        )}

        {!this.state.isLoaded && placeholder}
        <img
          alt={this.props.src}
          key={this.props.id}
          id={`previewImage_${this.props.id}`}
          className={styles.image}
          style={
            this.state.isLoaded
              ? {
                  transform: `rotate(${this.state.rotation}deg)`
                }
              : { display: 'none' }
          }
          onLoad={this.onImageLoad}
          src={this.props.src}
        />
      </div>
    );
  }

  onImageLoad = () => {
    /*
      1 0 degrees – the correct orientation, no adjustment is required.
      2 0 degrees, mirrored – image has been flipped back-to-front.
      3 180 degrees – image is upside down.
      4 180 degrees, mirrored – image is upside down and flipped back-to-front.
      5 90 degrees – image is on its side.
      6 90 degrees, mirrored – image is on its side and flipped back-to-front.
      7 270 degrees – image is on its far side.
      8 270 degrees, mirrored – image is on its far side and flipped back-to-front.
    */
    let orientation =
      this.props.exif && this.props.exif.Orientation
        ? this.props.exif.Orientation
        : 0;
    let rot = 0;
    switch (orientation) {
      case 1:
      case 2:
      default:
        rot = 0;
        break;
      case 3:
      case 4:
        rot = 180;
        break;
      case 5:
      case 6:
        rot = 90;
        break;
      case 7:
      case 8:
        rot = 270;
        break;
    }
    clearTimeout(this.notFound);
    this.setState({ isLoaded: true, rotation: rot });
  };

  openModal = () => {
    if (this.props.src) this.setState({ zoom: true });
  };
  closeModal = () => {
    this.setState({ zoom: false });
  };
}
export default ImagePreview;

ImagePreview.defaultProps = {
  exif: null
};

ImagePreview.propTypes = {
  id: PropTypes.number,
  src: PropTypes.string.isRequired,
  exif: PropTypes.object
};
