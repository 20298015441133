import ReactDOM from 'react-dom';
import { makeMainRoutes } from './routes';
import * as Sentry from '@sentry/browser';
const routes = makeMainRoutes();
const RELEASE = '1.0.0';

/*
if (process.env.NODE_ENV === 'development') {
  const { worker } = require('./mocks/browser');
  worker.start();
}
*/

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: window._env_.REACT_APP_SENTRY_API,
    release: RELEASE
  });
}

console.warn(
  `[INFO]: Running in ${window._env_.REACT_APP_MODE} mode (${process.env.REACT_APP_BUILD_ID} | ${process.env.REACT_APP_BUILD_DATE})`
);
ReactDOM.render(routes, document.getElementById('root'));
