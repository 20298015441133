import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PopoverMenu from 'components/PopoverMenu.js';
import WatchForOutsideClick from 'components/WatchForOutsideClick';
import colors from 'colors';
import MediaPreview from 'components/MediaPreview';
import { DropArea } from 'components';
import {
  makeAssetURL,
  //extractCoordinatesFromExif,
  //readableFileSize,
  downloadFile
} from 'utility';
import { useSelector, useDispatch } from 'react-redux';
import CONST from 'constants.js';
import * as actions from 'state/actions';

const MediaLibrary = (props) => {
  const dispatch = useDispatch();
  const scrollContainerEl = React.createRef();

  const [filterOn, setFilterOn] = useState('');
  const [scrollTop, setScrollTop] = useState(0);
  const [selectedMediaId, setSelectedMediaId] = useState(props.selectedMediaId);
  const [exifDataDisplay, setExifDataDisplay] = useState(null);

  const prefs = useSelector((redux) => redux.app.prefs);
  const user = useSelector((redux) => redux.user);
  const availableMedia = useSelector((redux) => redux.media.available.media);
  let media = availableMedia?.sort((a, b) => {
    return a.name.localeCompare(b.name, 'en-US-u-kn-true');
  });
  const selectedMedia = media?.find((item) => item.id === selectedMediaId);

  if (filterOn.length > 0)
    media = media.filter((item) =>
      String(item.name).toLowerCase().includes(filterOn.toLowerCase())
    );

  const onReplace = (file) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = `${file.type.split('/')[0]}/*`;
    input.onchange = (e) => {
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (readerEvent) => {
        const fileType = readerEvent.target.result.split(';')[0].split(':')[1];
        props.onDataReplace(
          CONST.UPLOAD_TYPE.MEDIA,
          readerEvent.target.result,
          fileType,
          {
            mediaid: file.id
          }
        );
      };
    };
    input.click();
  };

  const onFileDrop = (e) => {
    if (props.selectOnly) return null;
    const isPreview = e.item?.id;
    const files = isPreview ? [e.files[0]] : e.files;
    files.forEach((droppedFile) => {
      const isTooBig =
        prefs.media_max_size.value &&
        droppedFile.size > prefs.media_max_size.value;
      const isAllowedType = isPreview
        ? ['image/png', 'image/jpeg', 'image/gif'].includes(droppedFile.type)
        : [
            'image/png',
            'image/jpeg',
            'image/gif',
            'video/mp4',
            'audio/mp3',
            'audio/mpeg',
            'audio/wav'
          ].includes(droppedFile.type);

      if (!isTooBig && isAllowedType) {
        const reader = new FileReader();
        reader.readAsDataURL(droppedFile);
        reader.onload = (e) => {
          props.onDataUpload(
            isPreview
              ? CONST.UPLOAD_TYPE.MEDIA_PREVIEW
              : CONST.UPLOAD_TYPE.MEDIA,
            e.target.result,
            droppedFile.type,
            isPreview ? { ...selectedMedia } : { name: droppedFile.name }
          );
        };
      } else {
        window.alert(
          isTooBig
            ? `Maximum file size: ${prefs.media_max_size.value}`
            : `Unsupported type: ${droppedFile.type} `
        );
      }
    });
  };

  const onDelete = (media) => {
    let inUse = false; // FIXME: Add in-use flag on media in tour (https://gitlab.com/theplacelab/issues/-/issues/169)
    let msg = inUse
      ? `${media.name} is in use by a media tour. You can use the replace function if you want to change the media, if you delete it, it may break the tour. Do you really want to permenantly delete this item?`
      : `Do you really want to permenantly delete ${media.name}? `;
    if (window.confirm(msg)) {
      dispatch(actions.deleteMedia(media.id));
    }
  };

  const onShowExif = (file) => {
    setExifDataDisplay(file.exif);
  };

  const matches =
    filterOn.length > 0
      ? media.length === 1
        ? `${media.length} match`
        : `${media.length} matches`
      : '';

  const fileCount =
    media?.length > 0
      ? `${availableMedia.length} ${
          availableMedia.length === 1 ? 'file' : 'files'
        }`
      : 'O files';

  if (exifDataDisplay) {
    const onClose = () => setExifDataDisplay(null);
    let exif = [];
    Object.keys(exifDataDisplay).forEach((key, idx) =>
      exif.push(
        <div
          key={idx}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <div
            style={{
              minWidth: '15rem',
              maxWidth: '15rem',
              opacity: '0.70',
              marginBottom: '0.5rem'
            }}
          >
            {key}
          </div>
          <div>{JSON.stringify(exifDataDisplay[key]).replace(/"/g, '')}</div>
        </div>
      )
    );
    return (
      <div style={styles.outerContainer}>
        <WatchForOutsideClick onOutsideClick={onClose}>
          <div style={styles.innerContainer}>
            <div
              onClick={onClose}
              style={{
                margin: '1rem',
                width: '96%',
                textAlign: 'right'
              }}
              className="fas fa-times-circle"
            />
            <div style={{ overflow: 'scroll', padding: '1rem' }}>{exif}</div>
          </div>
        </WatchForOutsideClick>
      </div>
    );
  }

  return (
    <div style={styles.outerContainer}>
      <WatchForOutsideClick
        onOutsideClick={() => {
          dispatch(actions.uiHide(CONST.UI.MEDIA_LIBRARY));
          if (typeof props.onOutsideClick === 'function')
            props.onOutsideClick();
        }}
      >
        <div style={styles.innerContainer}>
          <div style={styles.statusBar}>
            <div>
              <input
                style={{
                  width: '10rem',
                  height: '1.5rem',
                  margin: 0,
                  padding: '0 0 0 .5rem'
                }}
                value={filterOn}
                onChange={(e) => setFilterOn(e.target.value)}
                type="text"
              />
            </div>
            {(filterOn.length > 0 && (
              <>
                <div
                  style={{
                    margin: `0 0 0 -1.2rem`,
                    opacity: `0.5`
                  }}
                  onClick={() => setFilterOn('')}
                  className="fas fa-times-circle"
                />
                <div style={{ marginLeft: '1rem', flexGrow: 1 }}>{matches}</div>
              </>
            )) || <div style={{ marginLeft: '1rem', flexGrow: 1 }} />}

            <div
              style={{ width: '6rem', flexDirection: 'row', display: 'flex' }}
            >
              {fileCount}
              {user.isAuthenticated && !props.selectOnly && (
                <PopoverMenu
                  items={[
                    {
                      name: 'Custom Image (URL)',
                      icon: 'plus',
                      action: () => {
                        setFilterOn('NEW');
                        dispatch(
                          actions.createMedia({
                            data: {
                              name: `NEW IMAGE`,
                              url: '',
                              type: 'image/custom'
                            }
                          })
                        );
                      }
                    },
                    {
                      name: 'Custom Media (YouTube, Vimeo, etc....)',
                      icon: 'plus',
                      action: () => {
                        setFilterOn('NEW');
                        dispatch(
                          actions.createMedia({
                            data: {
                              name: `NEW MEDIA`,
                              url: '',
                              type: 'media/custom'
                            }
                          })
                        );
                      }
                    }
                  ]}
                >
                  <div style={{ marginLeft: '1rem' }} className="fas fa-plus" />
                </PopoverMenu>
              )}
            </div>
          </div>

          <DropArea onDrop={onFileDrop} dataset={{ 'data-target': 'filelist' }}>
            <div
              ref={scrollContainerEl}
              style={{
                overflow: 'scroll',
                height: `calc(80vh - ${selectedMedia ? 13.8 : 2.5}rem)`,
                maxHeight: `${selectedMedia ? 60 - 13.8 : 60 - 2.5}rem`
              }}
              onScroll={() => setScrollTop(scrollContainerEl.current.scrollTop)}
            >
              {media?.length === 0 && (
                <div style={styles.noneFound}>No Matches Found</div>
              )}
              {media?.map((item) => (
                <Item
                  selectOnly={props.selectOnly}
                  isAuthenticated={user.isAuthenticated}
                  scrollTop={scrollTop}
                  key={item.id}
                  isSelected={selectedMedia === item}
                  onSelectItem={() => {
                    const selected =
                      selectedMediaId === item.id ? null : item.id;
                    setSelectedMediaId(selected);
                    if (typeof props.onSelectItem === 'function')
                      props.onSelectItem({ selected });
                  }}
                  item={item}
                  onShowExif={() => onShowExif(item)}
                  onDelete={() => onDelete(item)}
                  onReplace={() => onReplace(item)}
                  onDownload={() =>
                    downloadFile(makeAssetURL(item.url), item.name, item.type)
                  }
                />
              ))}
            </div>
          </DropArea>

          {selectedMedia && (
            <div style={styles.previewArea}>
              <MediaPreview
                isDisabled={props.selectOnly}
                media={selectedMedia}
                onFileDrop={onFileDrop}
                onRemovePreview={() =>
                  dispatch(actions.deleteMediaPreview(selectedMedia))
                }
                onChange={(e) => {
                  if (e.id === 'name' && filterOn.length > 0)
                    setFilterOn(e.currentTarget.value);
                  dispatch(
                    actions.updateMedia({
                      ...selectedMedia,
                      [e.id]: e.value
                    })
                  );
                }}
              />
            </div>
          )}
        </div>
      </WatchForOutsideClick>
    </div>
  );
};

MediaLibrary.propTypes = {
  onDataReplace: PropTypes.func,
  onDataUpload: PropTypes.func
};

const Item = (props) => {
  let menu =
    !props.isAuthenticated && !props.selectOnly
      ? []
      : props.item.type.includes('custom')
      ? [
          {
            name: 'Delete',
            icon: 'trash',
            action: props.onDelete
          }
        ]
      : [
          {
            name: 'Delete',
            icon: 'trash',
            action: props.onDelete
          },
          {
            name: 'Download',
            icon: 'file-download',
            action: props.onDownload
          },
          {
            name: 'Replace',
            icon: 'retweet',
            action: props.onReplace
          }
        ];
  if (props.item.exif && Object.keys(props.item.exif).length > 0)
    menu.push({
      name: 'Show EXIF',
      icon: 'file-code',
      action: props.onShowExif
    });

  /*
  if (props.item.exif && extractCoordinatesFromExif(props.item.exif))
    menu.push({
      name: 'Drop pin at EXIF Coordinates',
      icon: 'map-marker-alt',
      action: () => console.log('Drop pin at EXIF Coordinates')
    });
    */

  return (
    <div
      className="fileItem"
      onClick={props.onSelectItem}
      style={{
        ...styles.fileRow,
        backgroundColor: props.isSelected
          ? colors.secondary
          : colors.transparent
      }}
    >
      <div style={styles.fileName}>{props.item.name}</div>
      <div style={styles.fileType}>{props.item.type}</div>
      {!props.selectOnly && (
        <div style={styles.fileButtons}>
          {menu.length > 0 && (
            <PopoverMenu
              header={{
                name: props.item.name,
                desc: props.item.type,
                icon: 'file'
              }}
              scrollTop={props.scrollTop}
              items={menu}
            />
          )}
        </div>
      )}
    </div>
  );
};

Item.propTypes = {
  selectOnly: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  isSelected: PropTypes.bool,
  item: PropTypes.object,
  onDelete: PropTypes.func,
  onDownload: PropTypes.func,
  onReplace: PropTypes.func,
  onSelectItem: PropTypes.func,
  onShowExif: PropTypes.func,
  scrollTop: PropTypes.number
};

const styles = {
  outerContainer: {
    zIndex: 5,
    position: 'fixed',
    top: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100vw',
    backgroundColor: colors.modalBackground
  },
  innerContainer: {
    borderRadius: '0.3rem',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    width: '90vw',
    minWidth: '20rem',
    maxWidth: '40rem',
    height: '80vh',
    maxHeight: '60rem',
    backgroundColor: 'white',
    boxShadow: colors.shadow
  },
  statusBar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '.5rem 0 .3rem .5rem',
    minHeight: '2rem',
    maxHeight: '2rem',
    backgroundColor: colors.primary
  },
  previewArea: {
    borderTop: `1px solid ${colors.uiBorder}`,
    backgroundColor: colors.uiBackground,
    minHeight: '11rem',
    maxHeight: '11rem',
    boxShadow: colors.shadow
  },
  fileRow: {
    fontFamily: colors.fontFamily,
    color: 'black',
    fontSize: '.7rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    height: '3rem'
  },
  fileName: {
    flexGrow: 1,
    width: '20rem',
    minWidth: '10rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingRight: '1rem',
    paddingLeft: '1rem'
  },
  fileButtons: {
    minWidth: '5rem',
    paddingRight: '2rem'
  },
  fileType: { flexGrow: 1, width: '10rem' },
  noneFound: {
    textAlign: 'center',
    marginTop: '10rem'
  }
};

export default MediaLibrary;
