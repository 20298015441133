import initialState from './init/ui';
import * as actions from 'state/actions';

export default function ui(state = initialState, action) {
  switch (action.type) {
    case actions.UI_HIDE_ALL: {
      const newState = { ...state };
      Object.keys(newState).forEach((key) => {
        if (!newState[key].isPinned) newState[key].isVisible = false;
      });
      return newState;
    }

    case actions.UI_HIDE: {
      const newState = { ...state };
      newState[action.payload].isVisible = false;
      return newState;
    }

    case actions.UI_SHOW: {
      const newState = { ...state };
      Object.keys(newState).forEach((key) => {
        if (!newState[key].isPinned) newState[key].isVisible = false;
      });
      newState[action.payload].isVisible = true;
      return newState;
    }

    case actions.UI_SHOW_TOGGLE: {
      const newState = { ...state };
      const isNowVisible = !newState[action.payload].isVisible;
      if (isNowVisible)
        Object.keys(newState).forEach((key) => {
          if (!newState[key].isPinned) newState[key].isVisible = false;
        });
      newState[action.payload].isVisible = isNowVisible;
      return newState;
    }

    case actions.UI_PIN_TOGGLE: {
      const newState = { ...state };
      const newPinnedState = !newState[action.payload].isPinned;
      if (newPinnedState) {
        newState[action.payload].isPinned = true;
        newState[action.payload].isVisible = true;
      } else {
        newState[action.payload].isPinned = false;
        newState[action.payload].isVisible = false;
      }
      return newState;
    }

    case actions.UI_UNPIN_ALL: {
      const newState = { ...state };
      Object.keys(newState).forEach((key) => {
        newState[key].isPinned = false;
        newState[key].isVisible = false;
      });
      return newState;
    }

    case actions.UI_PIN: {
      const newState = { ...state };
      newState[action.payload].isPinned = true;
      newState[action.payload].isVisible = true;
      return newState;
    }

    case actions.UI_UNPIN: {
      const newState = { ...state };
      newState[action.payload].isPinned = false;
      newState[action.payload].isVisible = false;
      return newState;
    }

    case actions.UI_SHOW_DIALOG:
      return { ...state, dialog: { ...action.payload, isVisible: true } };

    case actions.UI_HIDE_DIALOG: {
      return { ...state, dialog: { ...state.dialog, isVisible: false } };
    }

    case actions.UI_SHOW_FATAL_ERROR:
      return { ...state, errorScreen: { ...action.payload, isVisible: true } };

    case actions.UI_HIDE_FATAL_ERROR:
      return {
        ...state,
        errorScreen: { ...state.errorScreen, isVisible: false }
      };

    case actions.UI_UPDATE_PLAYBACK:
      return {
        ...state,
        playback: { ...state.playback, ...action.payload }
      };

    default:
      return state;
  }
}
