export default {
  mapDrawingTools: { isVisible: false },
  tourPreview: { isVisible: false },
  preferences: { isVisible: false },
  mediaLibrary: { isVisible: false },
  audioTour: { isVisible: false },
  inspector: { isVisible: false },
  mediaInspector: { isVisible: false },
  poiInspector: { isVisible: false },
  userManager: { isVisible: false },
  info: { isVisible: false },
  search: { isPinned: false, isVisible: false },
  timeFilter: { isPinned: false, isVisible: false },
  geosearch: { isVisible: false },
  userSelfManager: { isVisible: false },
  errorScreen: { isVisible: false },
  dialog: {
    isVisible: false,
    message: '',
    onConfirm: null,
    onCancel: null,
    cancelLabel: null,
    confirmLabel: null
  },
  playback: {
    timer: null,
    isPlaying: false,
    isPaused: false,
    pointer: 1,
    array: []
  }
};
