import React, { useState, useLayoutEffect, useEffect } from 'react';
import PropTypes from 'prop-types';
import WatchForOutsideClick from 'components/WatchForOutsideClick';
import colors from 'colors';
const NO_SELECTION = -1;

const menuItemHeight = '1.5 rem';
const iconContainerSize = '2rem';
const iconSize = '1.5rem';

const PopoverMenu = (props) => {
  const anchorEl = React.createRef();
  const scrollEl = React.createRef();
  const containerEl = React.createRef();
  const arrowEl = React.createRef();
  const [selectedItem, setSelectedItem] = useState(NO_SELECTION);
  const [windowSize, setWindowSize] = useState({
    innerHeight: 0,
    innerWidth: 0
  });

  const layoutOptions = () => {
    let padding = 12;
    let optionCommon = {
      arrowBorderTop: `1rem solid transparent`,
      arrowBorderLeft: `1rem solid transparent`,
      arrowBorderBottom: `1rem solid transparent`,
      arrowBorderRight: `1rem solid transparent`,
      percentVisible: 0
    };
    return [
      {
        ...optionCommon,
        top: `${
          anchorEl.current.offsetTop +
          anchorEl.current.offsetHeight -
          3 * padding -
          props.scrollTop
        }px`,
        left: `${
          anchorEl.current.offsetLeft -
          containerEl.current.offsetWidth -
          padding
        }px`,
        arrowMargin: `${padding}px 0 0 ${containerEl.current.offsetWidth}px`,
        arrowBorderLeft: `1rem solid ${
          props.header ? colors.uiBorder : colors.uiBackground
        }`
      },
      {
        ...optionCommon,
        top: `${
          anchorEl.current.offsetTop +
          anchorEl.current.offsetHeight / 2 -
          containerEl.current.offsetHeight / 2 -
          props.scrollTop
        }px`,
        left: `${
          anchorEl.current.offsetLeft -
          containerEl.current.offsetWidth -
          padding
        }px`,
        arrowMargin: `${
          containerEl.current.offsetHeight / 2 -
          arrowEl.current.offsetHeight / 2
        }px 0 0 ${containerEl.current.offsetWidth}px`,
        arrowBorderLeft: `1rem solid ${colors.uiBackground}`
      },
      {
        ...optionCommon,
        top: `${
          anchorEl.current.offsetTop -
          containerEl.current.offsetHeight +
          padding * 3 -
          props.scrollTop
        }px`,
        left: `${
          anchorEl.current.offsetLeft -
          containerEl.current.offsetWidth -
          padding
        }px`,
        arrowMargin: `${containerEl.current.offsetHeight - padding * 3}px 0 0 ${
          containerEl.current.offsetWidth
        }px`,
        arrowBorderLeft: `1rem solid ${colors.uiBackground}`
      },
      {
        ...optionCommon,
        top: `${
          anchorEl.current.offsetTop -
          containerEl.current.offsetHeight -
          padding -
          props.scrollTop
        }px`,
        left: `${
          anchorEl.current.offsetLeft -
          containerEl.current.offsetWidth +
          padding * 3
        }px`,
        arrowMargin: `${containerEl.current.offsetHeight}px 0 0 ${
          containerEl.current.offsetWidth -
          arrowEl.current.offsetWidth -
          padding
        }px`,
        arrowBorderTop: `1rem solid ${colors.uiBackground}`
      },
      {
        ...optionCommon,
        top: `${
          anchorEl.current.offsetTop -
          containerEl.current.offsetHeight -
          padding -
          props.scrollTop
        }px`,
        left: `${
          anchorEl.current.offsetLeft -
          containerEl.current.offsetWidth / 2 +
          anchorEl.current.offsetWidth / 2
        }px`,
        arrowMargin: `${containerEl.current.offsetHeight}px 0 0 ${
          containerEl.current.offsetWidth / 2 - arrowEl.current.offsetWidth / 2
        }px`,
        arrowBorderTop: `1rem solid ${colors.uiBackground}`
      },
      {
        ...optionCommon,
        top: `${
          anchorEl.current.offsetTop -
          containerEl.current.offsetHeight -
          padding -
          props.scrollTop
        }px`,
        left: `${
          anchorEl.current.offsetLeft +
          anchorEl.current.offsetWidth -
          padding * 3
        }px`,
        arrowMargin: `${containerEl.current.offsetHeight}px 0 0 ${
          arrowEl.current.offsetWidth - padding
        }px`,
        arrowBorderTop: `1rem solid ${
          props.header ? colors.uiBorder : colors.uiBackground
        }`
      },
      {
        ...optionCommon,
        top: `${
          anchorEl.current.offsetTop -
          containerEl.current.offsetHeight +
          padding * 3 -
          props.scrollTop
        }px`,
        left: `${
          anchorEl.current.offsetLeft + anchorEl.current.offsetWidth + padding
        }px`,
        arrowMargin: `${
          containerEl.current.offsetHeight - padding * 3
        }px 0 0 ${-arrowEl.current.offsetWidth}px`,
        arrowBorderRight: `1rem solid ${colors.uiBackground}`
      },
      {
        ...optionCommon,
        top: `${
          anchorEl.current.offsetTop +
          anchorEl.current.offsetHeight / 2 -
          containerEl.current.offsetHeight / 2 -
          props.scrollTop
        }px`,
        left: `${
          anchorEl.current.offsetLeft + anchorEl.current.offsetWidth + padding
        }px`,
        arrowMargin: `${
          containerEl.current.offsetHeight / 2 -
          arrowEl.current.offsetHeight / 2
        }px 0 0 ${-arrowEl.current.offsetWidth}px`,
        arrowBorderRight: `1rem solid ${colors.uiBackground}`
      },
      {
        ...optionCommon,
        top: `${
          anchorEl.current.offsetTop +
          anchorEl.current.offsetHeight -
          padding * 3 -
          props.scrollTop
        }px`,
        left: `${
          anchorEl.current.offsetLeft + anchorEl.current.offsetWidth + padding
        }px`,
        arrowMargin: `${padding}px 0 0 ${-arrowEl.current.offsetWidth}px`,
        arrowBorderRight: `1rem solid ${
          props.header ? colors.uiBorder : colors.uiBackground
        }`
      },
      {
        ...optionCommon,
        top: `${
          anchorEl.current.offsetTop + anchorEl.current.offsetHeight + padding
        }px`,
        left: `${
          anchorEl.current.offsetLeft +
          anchorEl.current.offsetWidth -
          padding * 3
        }px`,
        arrowMargin: `${-arrowEl.current.offsetHeight}px 0 0 ${
          arrowEl.current.offsetWidth - padding
        }px`,
        arrowBorderBottom: `1rem solid ${
          props.header ? colors.uiBorder : colors.uiBackground
        }`
      },
      {
        ...optionCommon,
        top: `${
          anchorEl.current.offsetTop +
          anchorEl.current.offsetHeight +
          padding -
          props.scrollTop
        }px`,
        left: `${
          anchorEl.current.offsetLeft +
          anchorEl.current.offsetWidth / 2 -
          containerEl.current.offsetWidth / 2
        }px`,
        arrowMargin: `${-arrowEl.current.offsetHeight}px 0 0 ${
          containerEl.current.offsetWidth / 2 - arrowEl.current.offsetWidth / 2
        }px`,
        arrowBorderBottom: `1rem solid ${
          props.header ? colors.uiBorder : colors.uiBackground
        }`
      },
      {
        ...optionCommon,
        top: `${
          anchorEl.current.offsetTop +
          anchorEl.current.offsetHeight +
          padding -
          props.scrollTop
        }px`,
        left: `${
          anchorEl.current.offsetLeft -
          containerEl.current.offsetWidth +
          padding * 3
        }px`,
        arrowMargin: `${-arrowEl.current.offsetHeight}px 0 0 ${
          containerEl.current.offsetWidth -
          arrowEl.current.offsetWidth -
          padding
        }px`,
        arrowBorderBottom: `1rem solid ${
          props.header ? colors.uiBorder : colors.uiBackground
        }`
      }
    ];
  };

  const selectBestLayout = (options, containerEl, forceLayout) => {
    //https://stackoverflow.com/questions/9324339/how-much-do-two-rectangles-overlap
    const windowBounds = [
      { x: 0, y: 0 },
      {
        x: window.innerWidth,
        y: window.innerHeight
      }
    ];

    let previousArea = 0;
    let selectedOption = 0;
    options.forEach((option, idx) => {
      const x = parseInt(option.left, 10);
      const y = parseInt(option.top, 10);
      const thisBounds = [
        { x, y },
        {
          x: x + containerEl.offsetWidth,
          y: y + containerEl.offsetHeight
        }
      ];

      const intersectingArea =
        Math.max(
          0,
          Math.min(thisBounds[1].x, windowBounds[1].x) -
            Math.max(thisBounds[0].x, windowBounds[0].x)
        ) *
        Math.max(
          0,
          Math.min(thisBounds[1].y, windowBounds[1].y) -
            Math.max(thisBounds[0].y, windowBounds[0].y)
        );

      if (intersectingArea > previousArea) {
        previousArea = intersectingArea;
        selectedOption = idx;
      }
    });
    return options[forceLayout ? forceLayout : selectedOption];
  };

  const assignLayout = () => {
    if (anchorEl.current && arrowEl.current && containerEl.current) {
      let layout = selectBestLayout(
        layoutOptions(),
        containerEl.current,
        props.forceLayout
      );
      layout = { ...layout, ...props.layout };
      containerEl.current.style.position = 'fixed';
      arrowEl.current.style.margin = layout.arrowMargin;
      arrowEl.current.style.borderTop = layout.arrowBorderTop;
      arrowEl.current.style.borderRight = layout.arrowBorderRight;
      arrowEl.current.style.borderBottom = layout.arrowBorderBottom;
      arrowEl.current.style.borderLeft = layout.arrowBorderLeft;
      containerEl.current.style.top = layout.top;
      containerEl.current.style.left = layout.left;
      if (props.resetToTop) scrollEl.current.scrollTop = 0;
      if (!props.showArrow) arrowEl.current.style.display = 'none';
    }
  };

  useLayoutEffect(() => {
    const onResize = () => {
      if (
        window.innerHeight !== windowSize.innerHeight ||
        window.innerWidth !== windowSize.innerWidth
      ) {
        assignLayout();
        if (containerEl.current) containerEl.current.style.display = 'none';
        setWindowSize({
          innerWidth: window.innerWidth,
          innerHeight: window.innerHeight
        });
      }
    };
    onResize();
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  });

  useEffect(() => {
    assignLayout();
    if (containerEl.current) containerEl.current.style.display = 'none';
  }, [props.scrollTop]);

  return (
    <WatchForOutsideClick
      onOutsideClick={() => {
        if (containerEl.current) containerEl.current.style.display = 'none';
      }}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
          containerEl.current.style.display =
            containerEl.current.style.display === 'none' ? 'flex' : 'none';
          assignLayout();
        }}
      >
        {(props.children && (
          <div
            style={{
              position: 'relative'
            }}
            ref={anchorEl}
          >
            {props.children}
          </div>
        )) || (
          <div ref={anchorEl} style={styles.button}>
            <div
              style={{
                position: 'relative',
                top: '-.45rem',
                left: '-.4rem',
                color: colors.uiBorder
              }}
              className={`fas fa-${props.icon ? props.icon : 'ellipsis-h'}`}
            />
          </div>
        )}
      </div>

      <div
        onClick={(e) => e.stopPropagation()}
        className={'popover'}
        ref={containerEl}
        style={{ ...styles.popoverContainer }}
      >
        <div ref={arrowEl} id="arrow" style={styles.popoverArrow} />
        {props.header && (
          <div style={styles.header}>
            <Items data={[props.header]} />
          </div>
        )}
        <div
          style={styles.list}
          ref={scrollEl}
          onScroll={() => setSelectedItem(NO_SELECTION)}
        >
          <Items
            parentEl={scrollEl}
            data={props.items}
            selectedItem={selectedItem}
            onSelectItem={(id) => {
              setSelectedItem(id);
            }}
            onCloseMenu={() => (containerEl.current.style.display = 'none')}
          />
        </div>
      </div>
    </WatchForOutsideClick>
  );
};

const Items = (props) => {
  let items = [];
  if (!props.data) return null;
  props.data.forEach((item, idx) => {
    const iconClass = `fas fa-${item.icon}`;
    let id;
    let timer;
    const onBegin = (originalEvent) => {
      originalEvent.stopPropagation();
      originalEvent.preventDefault();
      id = parseInt(originalEvent.currentTarget.dataset.id, 10);
      let scrollTopAtStart = props.parentEl?.current.scrollTop;
      clearTimeout(timer);
      timer = setTimeout(
        () => {
          if (scrollTopAtStart === props.parentEl?.current.scrollTop)
            if (props.onSelectItem) props.onSelectItem(id);
        },
        originalEvent.type === 'mousedown' ? 0 : 100
      );
    };
    const onEnd = (e) => {
      e.stopPropagation();
      e.preventDefault();
      clearTimeout(timer);
      if (props.selectedItem !== NO_SELECTION) {
        if (props.onSelectItem) props.onSelectItem(NO_SELECTION);
        if (item.action) item.action(e);
        if (props.onCloseMenu) props.onCloseMenu(e);
      }
    };

    if (item.name === 'divider') {
      items.push(
        <div key={idx} style={styles.hruleContainer}>
          <hr style={styles.hrule} />
        </div>
      );
    } else {
      items.push(
        <div
          className={'itemRow'}
          key={idx}
          style={
            props.selectedItem === idx
              ? {
                  ...styles.menuItem,
                  color: colors.white,
                  backgroundColor: colors.secondary
                }
              : styles.menuItem
          }
          data-id={idx}
          onTouchEnd={onEnd}
          onTouchCancel={onEnd}
          onMouseDown={(e) => onBegin(e)}
          onTouchStart={(e) => onBegin(e)}
          onMouseUp={onEnd}
        >
          {(item.icon && (
            <div style={styles.rowIcon}>
              {(item.icon.includes('http') && (
                <img
                  style={{ height: iconContainerSize }}
                  src={item.icon}
                  alt={item.name}
                />
              )) || <div style={styles.faIcon} className={iconClass} />}
            </div>
          )) || (
            <div style={{ ...styles.rowIcon, borderColor: 'transparent' }} />
          )}
          <div>
            <div style={styles.itemName}>{item.name}</div>
            <div style={styles.itemDescription}>{item.desc}</div>
          </div>
        </div>
      );
    }
  });
  return items;
};

const styles = {
  popoverContainer: {
    color: 'black',
    position: 'absolute',
    zIndex: 20,
    fontFamily: colors.fontFamily,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    backgroundColor: colors.uiBackground,
    width: '20rem',
    padding: '0',
    borderRadius: '0.3rem',
    boxShadow: colors.shadow,
    maxHeight: '20rem',
    transition: 'opacity .1s linear'
  },
  popoverArrow: {
    position: 'absolute',
    width: 0,
    height: 0,
    border: '1rem solid transparent'
  },
  button: {
    position: 'relative',
    height: '.1rem',
    width: '.1rem',
    fontSize: '1rem',
    lineHeight: '1rem',
    textAlign: 'center',
    margin: '1rem'
  },
  header: {
    backgroundColor: colors.uiBorder,
    overflow: 'hidden',
    height: '3.3rem',
    borderBottom: '1px solid white',
    borderRadius: '0.3rem 0.3rem 0 0'
  },
  list: {
    overflow: 'scroll'
  },
  menuItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '.5rem 0 .5rem 0',
    height: menuItemHeight,
    fontSize: menuItemHeight,
    lineHeight: menuItemHeight,
    transition: 'background-color .25s linear'
  },
  rowIcon: {
    width: iconContainerSize,
    minWidth: iconContainerSize,
    textAlign: 'center',
    borderRadius: iconContainerSize,
    height: iconContainerSize,
    border: '1px solid darkgrey',
    overflow: 'hidden',
    margin: '0 0 0 1rem'
  },
  faIcon: {
    fontSize: iconSize,
    lineHeight: iconContainerSize,
    color: colors.primary
  },
  itemName: {
    flexGrow: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    marginLeft: '1rem',
    width: '12rem'
  },
  itemDescription: {
    fontSize: '.5rem',
    flexGrow: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    marginLeft: '1rem'
  },
  hruleContainer: { height: '.1rem' },
  hrule: { margin: 0, padding: 0, borderColor: colors.uiBorder }
};

export default PopoverMenu;

PopoverMenu.defaultProps = {
  scrollTop: 0,
  showArrow: true,
  resetToTop: false
};

PopoverMenu.propTypes = {
  children: PropTypes.any,
  forceLayout: PropTypes.any,
  header: PropTypes.any,
  icon: PropTypes.any,
  items: PropTypes.array.isRequired,
  layout: PropTypes.any,
  resetToTop: PropTypes.bool,
  scrollTop: PropTypes.number,
  showArrow: PropTypes.bool
};
