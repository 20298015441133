import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './AlertBar.module.css';

class AlertBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: ''
    };
    window.alertBar = (text, options) => {
      let event = new CustomEvent('alertBar');
      event.text = text;
      event.options = options;
      window.dispatchEvent(event);
    };
  }

  componentDidMount() {
    window.addEventListener('alertBar', this.onIncomingAlert);
    var s = document.getElementById('statusBar');
    s.style.opacity = 0;
    s.style.zIndex = 0;
  }

  componentWillUnmount() {
    window.removeEventListener('alertBar', this.onIncomingAlert);
  }

  onIncomingAlert = (e) => {
    var s = document.getElementById('statusBar');
    s.style.opacity = 1;
    s.style.zIndex = 20000;
    if (e.options?.color) s.style.backgroundColor = e.options.color;
    this.setState({ text: e.text });
    this.fadeOut();
  };

  fadeOut = () => {
    var s = document.getElementById('statusBar');
    if (!s) return;
    let fade = () => {
      s.style.opacity -= 0.1;
      if (s.style.opacity < 0) {
        s.style.opacity = 0;
        s.style.zIndex = 0;
      } else {
        setTimeout(fade, 30);
      }
    };
    setTimeout(() => {
      fade();
    }, 1000);
  };

  render() {
    return (
      <div className={styles.container} id="statusBar">
        {this.state.text}
      </div>
    );
  }
}
export default AlertBar;

AlertBar.propTypes = {
  text: PropTypes.string
};
