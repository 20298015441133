import CONST from 'constants.js';

export const readableFileSize = (bytes, si = true) => {
  let thresh = si ? 1000 : 1024;
  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }
  let units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  do {
    bytes /= thresh;
    ++u;
  } while (Math.abs(bytes) >= thresh && u < units.length - 1);
  return bytes.toFixed(1) + ' ' + units[u];
};

export const onReload = () => {
  // eslint-disable-next-line
  window.location.href = window.location.href;
};

export const arrayOfNumberOptionsFrom = (min, max) => {
  return [...Array(max + 1).keys()]
    .map((count) => {
      if (count >= min) {
        return { id: count, name: count };
      } else {
        return null;
      }
    })
    .filter((option) => option);
};

export const missingValue = (variable) => {
  if (!Array.isArray(variable)) {
    variable = [variable];
  }

  let isMissing = false;
  variable.forEach((value) => {
    if (!isMissing) {
      isMissing =
        value === null ||
        typeof value === 'undefined' ||
        (typeof value === 'string' && value.trim().length === 0);
    }
  });
  return isMissing;
};

export const decodeToken = (token) => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  return JSON.parse(window.atob(base64));
};

export const downloadFile = (url, name, type) => {
  fetch(url, {
    method: 'GET'
  })
    .then(function (resp) {
      return resp.blob();
    })
    .then(function (blob) {
      const newBlob = new Blob([blob], {
        type,
        charset: 'UTF-8'
      });

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }
      const data = window.URL.createObjectURL(newBlob);
      const link = document.createElement('a');
      link.dataType = 'json';
      link.href = data;
      link.download = name;
      link.dispatchEvent(new MouseEvent('click'));
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
      }, 60);
    });
};

export const exportDataToFile = (data, fileName, formatAsJSON) => {
  const a = document.createElement('a');
  const type = fileName.split('.').pop();
  a.href = URL.createObjectURL(
    new Blob([formatAsJSON ? JSON.stringify(data) : data], {
      type: `text/${type === 'txt' ? 'plain' : type}`
    })
  );
  a.download = fileName;
  a.click();
};

export const coerceJSON = (string) => {
  while (typeof string === 'string') {
    string = JSON.parse(string);
  }
  return string;
};

export const toDataUrl = (url, callback) => {
  var xhr = new XMLHttpRequest();
  xhr.onload = function () {
    var reader = new FileReader();
    reader.onloadend = function () {
      callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open('GET', url);
  xhr.responseType = 'blob';
  xhr.send();
};

export const loadAsset = (filename, filetype) => {
  let fileref;
  switch (filetype) {
    case 'js':
      fileref = document.createElement('script');
      fileref.setAttribute('type', 'text/javascript');
      fileref.setAttribute('src', filename);
      break;

    case 'css':
      fileref = document.createElement('link');
      fileref.setAttribute('rel', 'stylesheet');
      fileref.setAttribute('type', 'text/css');
      fileref.setAttribute('href', filename);
      break;

    default:
      console.log("I don't know how to load assets of type: " + filetype);
      return;
  }
  document.getElementsByTagName('head')[0].appendChild(fileref);
};

export const getJsonFromUrl = () => {
  var query = window.location.search.substr(1);
  var result = {};
  query.split('&').forEach(function (part) {
    var item = part.split('=');
    result[item[0].toLowerCase()] = decodeURIComponent(item[1]);
  });
  return result;
};

export const compareWithOptions = (a, b, isCaseSensitive, isExactMatch) => {
  if (!a || !b) return false;
  a = isCaseSensitive ? a : a.toLowerCase();
  b = isCaseSensitive ? b : b.toLowerCase();
  return isExactMatch ? a === b : a.includes(b);
};

export const debounce = function debounce(func, wait, immediate) {
  // https://davidwalsh.name/javascript-debounce-function
  // Returns a function, that, as long as it continues to be invoked, will not
  // be triggered. The function will be called after it stops being called for
  // 'wait' milliseconds. If `immediate` is passed, trigger the function on the
  // leading edge, instead of the trailing.
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

export const addEvent = (obj, e, fn) => {
  //https://bradsknutson.com/blog/javascript-detect-mouse-leaving-browser-window/
  if (obj.addEventListener) {
    obj.addEventListener(e, fn, false);
  } else if (obj.attachEvent) {
    obj.attachEvent('on' + e, fn);
  }
};

export const hexToRgb = (hex = 'FFFFFF') => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null;
};

export const dataURItoBlob = (dataURI) => {
  //https://gist.github.com/fupslot/5015897
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  /*
	var mimeString = dataURI
		.split(",")[0]
		.split(":")[1]
		.split(";")[0];
*/
  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  var bb = new Blob([ab]);
  return bb;
};

export const makeAssetURL = (url) => {
  if (!url || url.includes('http')) {
    return url;
  } else {
    return `${window._env_.REACT_APP_ASSET_SERVER}/${window._env_.REACT_APP_SERVER_S3_APPID}/${url}`;
  }
};

export const getByID = (id, object) => {
  if (missingValue([id, object])) {
    console.warn('GetByID: Neither ID nor object should be null');
    return null;
  }
  let found = null;
  object.forEach((item) => {
    if (parseInt(item.id) === parseInt(id)) {
      found = item;
    }
  });
  return JSON.parse(JSON.stringify(found));
};

export const formatFilesize = (sizeInBytes, includeLabel) => {
  let kB = Math.round(sizeInBytes * 0.001 * 100) / 100;
  let MB = Math.round(sizeInBytes * 0.000001 * 100) / 100;
  let GB = Math.round(sizeInBytes * 0.000000001 * 100) / 100;
  let TB = Math.round(sizeInBytes * 0.000000000001 * 100) / 100;

  let retval;
  if (TB >= 1) {
    retval = includeLabel ? `${TB} TB` : TB;
  } else if (GB >= 1) {
    retval = includeLabel ? `${GB} GB` : GB;
  } else if (MB >= 1) {
    retval = includeLabel ? `${MB} MB` : MB;
  } else if (kB >= 1) {
    retval = includeLabel ? `${kB} kB` : kB;
  } else {
    retval = includeLabel ? `${sizeInBytes} bytes` : sizeInBytes;
  }
  return retval;
};

export const dangerouslyLoadContentIntoDiv = (url, elementid, cb) => {
  fetch(url)
    .then((response) => response.text())
    .then((html) => {
      document.getElementById(elementid).innerHTML = html;
      cb();
    })
    .catch((error) => {
      console.warn(error);
    });
};

export const detectEnvironment = () => {
  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/android/i.test(userAgent)) return CONST.PLATFORM.ANDROID;

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream)
    return CONST.PLATFORM.IOS;

  return CONST.PLATFORM.STANDARD;
};

export const isIsoDate = (str) => {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
  var d = new Date(str);
  return d.toISOString() === str;
};

export const base64ToArrayBuffer = (base64) => {
  base64 = base64.replace(/^data:([^;]+);base64,/gim, '');
  let binaryString = atob(base64);
  let len = binaryString.length;
  let bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
};

export const extractCoordinatesFromExif = (exifData) => {
  if (exifData && exifData.GPSLatitude) {
    const lat = convertDMSToDD(
      exifData.GPSLatitude[0],
      exifData.GPSLatitude[1],
      exifData.GPSLatitude[2],
      exifData.GPSLatitudeRef
    );
    const lng = convertDMSToDD(
      exifData.GPSLongitude[0],
      exifData.GPSLongitude[1],
      exifData.GPSLongitude[2],
      exifData.GPSLongitudeRef
    );
    return { lat, lng };
  }
  return null;
};

export const convertDMSToDD = (degrees, minutes, seconds, direction) => {
  var dd = degrees + minutes / 60 + seconds / (60 * 60);
  if (direction === 'S' || direction === 'W') {
    dd = dd * -1;
  } // Don't do anything for N or E
  return dd;
};
