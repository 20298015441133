import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import React, { useState, useEffect, useReducer } from 'react';
import { lineString, point } from '@turf/helpers';
import buffer from '@turf/buffer';
import { DropArea } from 'components';
import uuidv4 from 'uuid/v4';
import { Button, IconButton } from 'components';
import * as actions from 'state/actions';
import CONST from 'constants.js';
import Layer from './Layer.js';
import helper from './helper.js';
import GPSKalmanFilter from 'utility/kalman.js';
import turfAlong from '@turf/along';
import turfDistance from '@turf/distance';

const medianOf = (arr = []) => {
  let median = arr[0];
  if (arr.length <= 1) return median;
  arr = arr.sort((a, b) => a - b);
  let isEven = arr.length % 2 === 0;
  if (isEven) {
    median = (arr[arr.length / 2 - 1] + arr[arr.length / 2 - 1 + 1]) / 2;
  } else {
    median = arr[(Math.ceil(arr.length) - 1) / 2];
  }
  return median;
};

const kalmanFilter = new GPSKalmanFilter();
let sample = [];
let sampleDistance = [];
let previousCoordinate = { lng: 0, lat: 0 };
const SAMPLE_SIZE = 10;
const REJECTION_THRESHOLD_MAX = 20;
let renderCount = 0;
const AudioTourPreview = () => {
  const [sampleSize, setSampleSize] = useState(SAMPLE_SIZE);
  const [threshold, setThreshold] = useState(REJECTION_THRESHOLD_MAX);
  const [mouseCoord, setMouseCoord] = useState(null);
  const [isMapLocked, setIsMapLocked] = useState(false);
  const [isTracing, setIsTracing] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [isNoisy, setIsNoisy] = useState(false);
  const [isThresholding, setIsThresholding] = useState(false);
  const [isInterpolating, setIsInterpolating] = useState(false);
  const [markersVisible, setMarkersVisible] = useState(true);
  const [layers, setLayers] = useState([]);

  const dispatch = useDispatch();
  const isInitialized = useSelector((redux) => redux.app.isInitialized);
  const map = useSelector((redux) => redux.app.map);
  const uiMode = useSelector((redux) => redux.app.uiMode);
  const drawing = useSelector((redux) => redux.app.drawing);
  const mapId = useSelector((redux) => redux.app.id);
  const audioTour = useSelector((redux) => redux.audioTour);
  const hasIdealPath = audioTour.config.ideal_path?.type === 'LineString';
  const user = useSelector((redux) => redux.user);
  const availablePoi = useSelector((redux) => redux.poi.all).sort(
    (a, b) => a.sortorder - b.sortorder
  );
  const availableProximity = useSelector((redux) => redux.proximity.all);
  let coordinate = mouseCoord;

  // We need forceUpdate because this particular module manipulates the DOM directly
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  useEffect(() => {
    if (uiMode !== CONST.UI_MODE.TOUR_PREVIEW)
      dispatch(actions.setUiMode(CONST.UI_MODE.TOUR_PREVIEW));
    if (isInitialized && user.isAuthenticated && map) {
      map.on('mousemove', (e) => setMouseCoord(e.lngLat));
    }

    const metersToKilometers = (m) => {
      return (m * 1.5) / 1000;
    };
    if (hasIdealPath) {
      addLayerToMap({
        isRemovable: false,
        id: 'idealPath',
        displayName: 'Ideal Path',
        data: audioTour.config.ideal_path,
        paint: {
          'line-color': '#FF00FF',
          'line-width': 1,
          'line-opacity': 1.0
        }
      });
      addLayerToMap({
        type: 'fill',
        isRemovable: false,
        id: 'idealPathBuffer',
        displayName: 'On Path',
        data: buffer(audioTour.config.ideal_path, metersToKilometers(20), {
          unit: 'kilometers'
        }),
        paint: {
          'fill-color': '#00FF00',
          'fill-opacity': 0.2
        }
      });

      let colors = ['#FFFF00', '#FF9625'];
      availableProximity.forEach((proxTrigger, idx) => {
        let randomColor =
          '#' +
          (0x1000000 + Math.random() * 0xffffff).toString(16).substr(1, 6);
        addLayerToMap({
          type: 'fill',
          isRemovable: false,
          id: `idealPathBuffer${proxTrigger.options.distance}`,
          displayName: `${proxTrigger.options.distance}M`,
          data: buffer(
            audioTour.config.ideal_path,
            metersToKilometers(proxTrigger.options.distance),
            {
              unit: 'kilometers'
            }
          ),
          paint: {
            'fill-color': colors[idx] ? colors[idx] : randomColor,
            'fill-opacity': 0.2
          }
        });
      });
    }

    return () => {
      if (map) {
        map.off('mousemove');
        dispatch(actions.setUiMode(CONST.UI_MODE.STANDARD));
        helper.removeLayerAndSource(map, 'indicator');
        helper.removeLayerAndSource(map, 'indicatorSnapped');
      }
      helper.mapOptions.forEach((item) => {
        if (map) map[item].enable();
      });
    };
  }, [hasIdealPath]);

  renderCount++;
  if (isNoisy) {
    if (hasIdealPath) {
      const distanceInKm = 20 / 1000;
      const along = turfAlong(audioTour.config.ideal_path, distanceInKm, {
        units: 'kilometers'
      });
      if (renderCount % 100 === 0)
        coordinate = {
          lat: along.geometry.coordinates[1],
          lng: along.geometry.coordinates[0]
        };
    }
  }

  let thresholdRejection = 0;
  if (isThresholding) {
    const distance = turfDistance(
      point([coordinate.lat, coordinate.lng]),
      point([previousCoordinate.lat, previousCoordinate.lng]),
      { units: 'meters' }
    );

    if (sampleDistance.length > sampleSize) {
      sampleDistance.shift();
    }
    if (distance <= threshold) sampleDistance.push(distance);
    if (distance >= medianOf(sampleDistance)) {
      thresholdRejection = distance;
    }
    previousCoordinate = { ...coordinate };
  }

  if (isFilter && thresholdRejection <= 0) {
    if (sample.length > sampleSize) {
      sample.shift();
    }
    sample.push(coordinate);

    let filteredSet = [];
    sample.forEach((coord) => {
      filteredSet.push(
        kalmanFilter.process(coord.lat, coord.lng, 0, Date.now())
      );
    });

    const latMedian = medianOf(filteredSet.map((item) => item[1]));
    const lngMedian = medianOf(filteredSet.map((item) => item[0]));
    const filteredCoordinate = { lat: latMedian, lng: lngMedian };
    coordinate = filteredCoordinate;
  }

  const distanceFromTrail =
    isTracing && thresholdRejection <= 0
      ? helper.updateIndicator(
          audioTour.config.ideal_path,
          map,
          coordinate,
          audioTour.config.ideal_path_threshold
        )
      : 99999;
  const onTrail = distanceFromTrail <= audioTour.config.ideal_path_threshold;

  const addLayerToMap = ({
    rawData,
    displayName,
    data,
    paint,
    type = 'line',
    id = uuidv4(),
    isRemovable = true
  }) => {
    if (!map.getStyle().sources[id]) {
      map.addSource(id, {
        type: 'geojson',
        data
      });
    }
    const newLayer = {
      id: id,
      type,
      source: id,
      paint
    };
    let foundLayer = map.getStyle().layers.find((layer) => {
      return layer.id.includes(id);
    });
    if (!foundLayer) {
      map.addLayer(newLayer);
      map.setLayoutProperty(id, 'visibility', 'visible');
      let newLayers = layers;
      layers.push({
        rawData,
        isRemovable,
        data,
        displayName,
        id,
        layer: newLayer,
        isVisible: true
      });
      setLayers(newLayers);
    }
  };

  const onDrop = (e) => {
    e.files.forEach((file) => {
      console.log(file);
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = (e) => {
        const importedLine = helper.parseCoordinatesFromGPXData(
          e.target.result
        );
        if (importedLine.length <= 0) {
          window.alert('Bad GPX Data');
          return;
        }
        addLayerToMap({
          displayName: file.name,
          data: lineString(importedLine),
          rawData: importedLine,
          paint: {
            'line-color': 'blue',
            'line-width': 1,
            'line-opacity': 0.5
          }
        });

        forceUpdate();
      };
    });
  };

  const generatePath = () => {
    const trailLine = availablePoi.map((poi) => [
      poi.coordinate.lng,
      poi.coordinate.lat
    ]);
    if (trailLine.length === 0) return;
    const generatedPath = lineString(trailLine);
    addLayerToMap({
      displayName: 'Auto-generated Path',
      data: generatedPath,
      paint: {
        'line-color': 'black',
        'line-width': 1,
        'line-opacity': 1.0
      }
    });
    forceUpdate();
  };

  const removeIdealPath = () => {
    if (window.confirm('Do you want to remove the ideal path? ')) {
      map.removeLayer('idealPath');
      map.removeSource('idealPath');
      const newLayers = layers.filter((item) => item.id !== 'idealPath');
      setLayers(newLayers);
      dispatch(
        actions.updateAudiotourConfig({
          mapId,
          e: { id: 'ideal_path', value: {} }
        })
      );
    }
  };

  const removeAllLayers = () => {
    layers.forEach((layer) => {
      if (layer.isRemovable) {
        map.removeLayer(layer.id);
        map.removeSource(layer.id);
      }
    });
    setLayers(layers.filter((layer) => !layer.isRemovable));
  };

  const toggleMapFreeze = () => {
    helper.mapOptions.forEach((item) => {
      if (isMapLocked) {
        map[item].enable();
      } else {
        map[item].disable();
      }
    });
    setIsMapLocked(!isMapLocked);
  };

  const toggleMarkerVisibility = () => {
    setMarkersVisible(!markersVisible);
    map.getStyle().layers.forEach((layer) => {
      if (layer.id.includes('pin') || layer.id.includes('circle')) {
        map.setLayoutProperty(
          layer.id,
          'visibility',
          markersVisible ? 'none' : 'visible'
        );
      }
    });
  };

  return (
    <DropArea onDrop={onDrop}>
      <div style={styles.container}>
        <div style={{ margin: '.5rem' }}>
          <Button onClick={toggleMapFreeze}>
            {isMapLocked ? `Unfreeze Map` : 'Freeze Map'}
          </Button>
          <Button onClick={toggleMarkerVisibility}>
            {markersVisible ? `HIDE Markers` : 'SHOW Markers'}
          </Button>
          <Button
            onClick={() => {
              if (uiMode === CONST.UI_MODE.MAP_DRAWING) {
                dispatch(actions.setUiMode(CONST.UI_MODE.TOUR_PREVIEW));
                if (drawing && Object.keys(drawing).length > 0) {
                  Object.keys(drawing).forEach((id) => {
                    const thisDrawing = drawing[id];
                    addLayerToMap({
                      displayName: thisDrawing.id,
                      data: thisDrawing.geometry,
                      paint: {
                        'line-color': 'purple',
                        'line-width': 1,
                        'line-opacity': 1.0
                      }
                    });
                  });
                }
              } else {
                dispatch(actions.setUiMode(CONST.UI_MODE.MAP_DRAWING));
              }
            }}
          >
            {uiMode === CONST.UI_MODE.MAP_DRAWING ? 'Save' : 'Draw'}
          </Button>
        </div>
        <div style={{ border: `1px solid black` }}>
          <div>
            <Button
              onClick={() => {
                helper.clearIndicator(map);
                setIsTracing(!isTracing);
              }}
            >
              {isTracing ? 'Trace: ON' : 'Trace: OFF'}
            </Button>
          </div>
          <div>
            <Button
              onClick={() => {
                helper.clearIndicator(map);
                setIsNoisy(!isNoisy);
              }}
            >
              {isNoisy ? 'Noise: ON' : 'Noise: OFF'}
            </Button>
          </div>
          <div>
            <Button
              isDisabled={!isTracing}
              onClick={() => {
                helper.clearIndicator(map);
                setIsThresholding(!isThresholding);
              }}
            >
              {isThresholding ? 'Thresholding: ON' : 'Thresholding: OFF'}
            </Button>
            <input
              type="text"
              value={threshold}
              onChange={(e) => setThreshold(e.target.value)}
            />
          </div>
          <div>
            <Button
              isDisabled={!isTracing}
              onClick={() => {
                helper.clearIndicator(map);
                setIsFilter(!isFilter);
              }}
            >
              {isFilter ? 'Filter: ON' : 'Filter: OFF'}
            </Button>
            <input
              type="text"
              value={sampleSize}
              onChange={(e) => setSampleSize(e.target.value)}
            />
          </div>
          <div>
            <Button
              isDisabled={true}
              onClick={() => {
                helper.clearIndicator(map);
                setIsInterpolating(!isInterpolating);
              }}
            >
              {isInterpolating ? 'Interpolation: ON' : 'Interpolation: OFF'}
            </Button>
          </div>
          <div>{distanceFromTrail?.toFixed(3)} meters from trail</div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <div
              style={{
                border: '1px solid black',
                height: '2rem',
                width: '2rem',
                backgroundColor: isTracing
                  ? onTrail
                    ? '#00FF00'
                    : 'red'
                  : '#999999'
              }}
            />
            <div>
              {onTrail ? 'ON Trail' : 'OFF Trail'} (threshold:
              {audioTour.config.ideal_path_threshold}M)
            </div>
          </div>
          {availableProximity.map((proxTrigger) => {
            const withinRange =
              distanceFromTrail <= parseFloat(proxTrigger.options.distance);
            return (
              <div
                key={proxTrigger.id}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
              >
                <div
                  style={{
                    border: '1px solid black',
                    height: '2rem',
                    width: '2rem',
                    backgroundColor: isTracing
                      ? withinRange
                        ? '#00FF00'
                        : 'red'
                      : '#999999'
                  }}
                />
                <div> Within {proxTrigger.options.distance}M</div>
              </div>
            );
          })}
        </div>
        <div style={styles.coordinates}>
          {mouseCoord
            ? `lat: ${mouseCoord.lat}, lng: ${mouseCoord.lng}`
            : '0,0'}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center'
          }}
        >
          <div
            style={{
              marginTop: '1rem',
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              height: '3rem',
              backgroundColor: hasIdealPath ? '#FF00FF' : 'orange'
            }}
          >
            <div
              style={{
                flexGrow: 1
              }}
            >
              {hasIdealPath
                ? '✅ Ideal Path is Set!'
                : ' ⚠️ IDEAL PATH NOT CONFIGURED! '}
            </div>
            {hasIdealPath && (
              <div>
                <IconButton
                  icon="trash"
                  isDisabled={!hasIdealPath}
                  onClick={removeIdealPath}
                />
              </div>
            )}
          </div>

          <div>
            <div>
              <div style={{ margin: '.5rem', textAlign: 'right' }}>
                <Button onClick={generatePath}>Auto-Generate</Button>
                <Button
                  isDisabled={layers.length === 0}
                  onClick={removeAllLayers}
                >
                  Remove All
                </Button>
              </div>
              <hr></hr>
              <div style={{ border: '1px dotted grey' }}>
                Drop GPX Here To Add Paths
              </div>
            </div>
            {layers?.map((layer) => {
              const isVisible =
                map.getLayoutProperty(layer.id, 'visibility') === 'visible';
              const onRemove = () => {
                map.removeLayer(layer.layer.id);
                map.removeSource(layer.layer.id);
                const newLayers = layers.filter((item) => item.id !== layer.id);
                setLayers(newLayers);
              };
              return (
                <Layer
                  map={map}
                  key={layer.id}
                  layer={layer}
                  isVisible={isVisible}
                  forceUpdate={forceUpdate}
                  onRemove={onRemove}
                  onFitToIdeal={() => {
                    addLayerToMap({
                      displayName: `${layer.displayName} (FIT)`,
                      data: lineString(
                        helper.nearestFitLine(
                          layer.rawData,
                          audioTour.config.ideal_path
                        )
                      ),
                      rawData: layer.rawData,
                      paint: {
                        'line-color': '#00FF00',
                        'line-width': 2,
                        'line-opacity': 1
                      }
                    });
                    forceUpdate();
                  }}
                  onEdit={() => {
                    map.removeLayer(layer.layer.id);
                    map.removeSource(layer.layer.id);
                    const newLayers = layers.filter(
                      (item) => item.id !== layer.id
                    );
                    setLayers(newLayers);
                    dispatch(actions.setUiMode(CONST.UI_MODE.MAP_DRAWING));
                    map.draw.add(layer.data);
                  }}
                />
              );
            })}
          </div>
        </div>
      </div>
    </DropArea>
  );
};

const styles = {
  container: {
    padding: '2rem',
    backgroundColor: '#FF000033',
    position: 'fixed',
    top: '40px',
    left: 0,
    width: '25rem',
    height: '100vh',
    zIndex: 5
  },
  coordinates: {
    backgroundColor: 'white',
    opacity: 0.8,
    border: '1px solid black',
    padding: '1rem',
    fontSize: '.8rem'
  }
};
AudioTourPreview.propTypes = {
  progress: PropTypes.any
};
AudioTourPreview.displayName = 'AudioTourPreview';
export default React.memo(AudioTourPreview);
