import * as actions from 'state/actions';
import { select, put, all } from 'redux-saga/effects';
import * as helper from './sagaHelper.js';
import CONST from 'constants.js';

export const getMediaConfig = function* (action) {
  switch (window._env_.REACT_APP_MODE) {
    case CONST.APP_MODE.STANDALONE:
    case CONST.APP_MODE.DEMO:
      console.error('ERROR: Standalone/Demo not working');
      /*
      yield put({
        type: actions.MEDIA_GET_CONFIG_RESPONSE,
        payload: {
          response: mediaconfig ? mediaconfig : null,
          request: action.payload
        }
      });*/
      break;

    default:
    case CONST.APP_MODE.API:
      try {
        let response;
        let authToken = localStorage.getItem('authtoken');
        if (!authToken) {
          response = yield helper.anonymousAPI(
            'GET',
            `mediaconfig?map_id=eq.${action.payload}`
          );
        } else {
          authToken = authToken.replace(/['"]+/g, '');
          response = yield helper.signedAPI(
            'GET',
            `s_mediaconfig?map_id=eq.${action.payload}`,
            authToken
          );
        }
        let response_json = yield response.json();
        yield put({
          type: actions.MEDIA_GET_CONFIG_RESPONSE,
          payload: { response: response_json, request: action.payload }
        });
      } catch (e) {
        yield put({
          type: actions.APP_SAGA_ERROR_NONFATAL,
          payload: {
            error: e,
            source: action.type
          }
        });
      }
  }
};

export const getMedia = function* (action) {
  try {
    let response;
    let authToken = localStorage.getItem('authtoken');
    if (authToken !== null) {
      authToken = authToken.replace(/['"]+/g, '');
      response = yield helper.signedAPI('GET', `s_media`, authToken);
    } else {
      response = yield helper.anonymousAPI('GET', `media`);
    }

    let response_json = yield response.json();
    yield put({
      type: actions.MEDIA_GET_RESPONSE,
      payload: { response: response_json, request: action.payload }
    });
  } catch (e) {
    yield put({
      type: actions.APP_SAGA_ERROR,
      payload: {
        error: e,
        source: action.type
      }
    });
  }
};

export const deleteMedia = function* (action) {
  const reduxState = yield select();
  try {
    let response = yield helper.signedAPI(
      'DELETE',
      `s_media?id=eq.${action.payload}`,
      reduxState.user.token,
      JSON.stringify(action.payload.data)
    );
    if (!response.ok) {
      let response_json = yield response.json();
      console.error(response_json);
    }

    yield put({
      type: actions.MEDIA_DELETE_RESPONSE,
      payload: {
        response: response,
        refresh: action.payload.refresh,
        map_id: reduxState.app.id,
        token: reduxState.user.token
      }
    });
    yield put({
      type: actions.SERVER_S3_FILE_DELETE,
      payload: { id: action.payload.url }
    });
    yield put({
      type: actions.SERVER_S3_FILE_DELETE,
      payload: { id: action.payload.preview }
    });
    yield put({ type: actions.MEDIA_GET });
  } catch (e) {
    yield put({
      type: actions.APP_SAGA_ERROR,
      payload: {
        error: e,
        source: action.type
      }
    });
  }
};

export const deleteMediaPreview = function* (action) {
  try {
    yield put({
      type: actions.SERVER_S3_FILE_DELETE,
      payload: { id: action.payload.preview }
    });
    yield put({
      type: actions.MEDIA_UPDATE_ENQUEUE,
      payload: { ...action.payload, preview: null, preview_exif: null }
    });
    yield put({ type: actions.MEDIA_GET });
  } catch (e) {
    yield put({
      type: actions.APP_SAGA_ERROR,
      payload: {
        error: e,
        source: action.type
      }
    });
  }
};

export const replaceMedia = function* (action) {
  yield put({
    type: actions.SERVER_S3_FILE_DELETE,
    payload: { id: action.payload.data.replaces.url }
  });
  yield createUpdateMedia({
    type: actions.MEDIA_UPDATE,
    payload: {
      id: action.payload.data.replaces.id,
      data: {
        url: action.payload.data.url,
        exif: action.payload.data.exif,
        type: action.payload.data.type
      },
      onComplete: action.payload.onComplete
    }
  });
};

export const createMedia = function* (action) {
  yield createUpdateMedia(action);
};

export const createUpdateMedia = function* (action) {
  try {
    const reduxState = yield select();
    let response = yield helper.signedAPI(
      action.type === actions.MEDIA_CREATE ? 'POST' : 'PATCH',
      action.type === actions.MEDIA_CREATE
        ? `s_media`
        : `s_media?id=eq.${action.payload.id}`,
      reduxState.user.token,
      JSON.stringify(action.payload.data).replace(/\\u0000/g, '')
    );

    if (!response.ok) {
      let response_json = yield response.json();
      console.error(response_json);
    }

    yield put({
      type:
        action.type === actions.MEDIA_CREATE
          ? actions.MEDIA_CREATE_RESPONSE
          : actions.MEDIA_UPDATE_RESPONSE,
      payload: {
        response: response,
        refresh: action.payload.refresh,
        map_id: reduxState.app.id,
        token: reduxState.user.token
      }
    });

    yield put({ type: actions.MEDIA_GET });

    if (typeof action.payload.onComplete === 'function')
      yield action.payload.onComplete();
  } catch (e) {
    yield put({
      type: actions.APP_SAGA_ERROR,
      payload: {
        error: e,
        source: action.type
      }
    });
  }
};

export const recordMediaChanges = function* () {
  const reduxState = yield select();
  yield all(
    reduxState.media.needsUpdate.map((item) => {
      return put({
        type: actions.API_PERFORM_CRUD,
        payload: {
          method: 'PATCH',
          endpoint: `s_media?id=eq.${item.id}`,
          type: actions.API_PERFORM_CRUD,
          data: { ...item }
        }
      });
    })
  );
  yield put({ type: actions.MEDIA_CLEAR_UPDATE_QUEUE });
};
