import initialState from './init/user';
import * as actions from 'state/actions';
import { decodeToken } from 'utility';

export default function api(state = initialState, action) {
  switch (action.type) {
    case actions.APP_LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        token: '',
        claims: ''
      };

    case actions.APP_LOGIN_RESPONSE:
      if (action.payload.response.ok) {
        const token = action.payload.json[0].token;
        return {
          ...state,
          isAuthenticated: true,
          token: token,
          claims: decodeToken(token)
        };
      } else {
        return {
          ...state,
          isAuthenticated: false,
          token: '',
          claims: ''
        };
      }

    default:
      return state;
  }
}
