import React from 'react';
import styles from './Inspector.module.css';
import PropTypes from 'prop-types';
//import Mousetrap from 'mousetrap';
import { IconButton } from 'components';
import FormInput, { FIELDTYPE } from 'components/FormInput';
import { Collapsable } from 'components';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from 'state/actions';
import poi from 'state/reducers/init/poi';

export const PoiInspector = (props) => {
  const dispatch = useDispatch();
  const currentPoi = useSelector((redux) =>
    redux.poi.all?.find((item) => item.id === redux.poi.current?.id)
  );
  if (!currentPoi) return null;
  const isDisabled = !props.isAuthenticated;
  const thisRep = props.poiRepresentationOptions.find(
    (rep) => rep.id === parseInt(currentPoi.representation_id, 10)
  );
  const repOptions = props.poiRepresentationOptions.filter(
    (item) => item.is_selectable
  );
  const onChange = (e) => {
    if (e.id === 'representation_config') {
      dispatch(
        actions.updatePoi({
          ...currentPoi,
          representation_config: {
            ...e.data.representation_config,
            [e.data.fieldName]: e.value.color ? e.value.color : e.value
          }
        })
      );
    } else {
      if (parseInt(e.id, 10).toString() === e.id.toString()) {
        let data = [...currentPoi.data];
        const existing = currentPoi.data.findIndex(
          (item) => item.dataType === parseFloat(e.id)
        );
        if (existing !== -1) {
          data[existing][e.key ? e.key : 'value'] = e.value;
        } else {
          data.push({ ...e.data, [e.key ? e.key : 'value']: e.value });
        }
        dispatch(actions.updatePoi({ ...currentPoi, data }));
      } else {
        dispatch(actions.updatePoi({ ...currentPoi, [e.id]: e.value }));
      }
    }
  };

  const buildRepOptionForm = () => {
    const rep = props.poiRepresentationOptions.find(
      (rep) => rep.id === currentPoi.representation_id
    );
    let defaultConfig = {};
    if (rep.template)
      rep.template.editableOptions.forEach((opt) => {
        defaultConfig[opt] = rep.template.options[opt].default;
      });

    const values = {
      ...defaultConfig,
      ...currentPoi.representation_config
    };

    let ui = [];
    if (rep.template?.editableOptions) {
      rep.template.editableOptions.forEach((fieldName, idx) => {
        ui.push(
          <FormInput
            key={idx}
            id="representation_config"
            label={rep.template.options[fieldName].display}
            type={rep.template.options[fieldName].formType}
            value={values[fieldName]}
            onChange={onChange}
            options={{
              selectionOptions: rep.template.options[fieldName],
              data: { fieldName, representation_config: values }
            }}
          />
        );
      });
    }
    return ui;
  };

  return (
    <div
      className={
        props.timeFilterVisible
          ? `${styles.inspector_container} ${styles.offset}`
          : styles.inspector_container
      }
    >
      <div style={{ overflow: 'hidden scroll' }}>
        <div className={styles.searchnav}>
          <div className={styles.backArrow}>
            <IconButton icon="search" onClick={props.onSearch} />
          </div>
          {(props.filteredPOI?.length > 0 && (
            <div className={styles.searchPagination}>
              <IconButton
                isDisabled={props.search.pointer === 1}
                onClick={() => {
                  //Prev
                  let newPointer = props.search.pointer - 1;
                  newPointer = newPointer <= 0 ? 1 : newPointer;
                  let selectedPoiID = props.filteredPOI[newPointer - 1].id;
                  props.onNavigate(newPointer, selectedPoiID);
                }}
                icon="arrow-circle-left"
              />
              <div style={{ flexGrow: 1, textAlign: 'center' }}>
                {props.search.options.keyword} ({props.search.pointer}/
                {props.filteredPOI?.length})
              </div>
              <IconButton
                isDisabled={props.search.pointer === props.filteredPOI?.length}
                onClick={() => {
                  //Next
                  let newPointer = props.search.pointer + 1;
                  newPointer =
                    newPointer > props.filteredPOI.length
                      ? props.filteredPOI.length
                      : newPointer;
                  let selectedPoiID = props.filteredPOI[newPointer - 1].id;
                  props.onNavigate(newPointer, selectedPoiID);
                }}
                icon="arrow-circle-right"
              />
            </div>
          )) || (
            <div className={styles.backArrow}>
              <IconButton
                icon="map-marker-alt"
                onClick={() => {
                  props.onNavigate(0, currentPoi.id);
                }}
              />
            </div>
          )}
        </div>
        <div style={{ paddingTop: '2rem', paddingBottom: '1rem' }}>
          {thisRep.is_selectable && !isDisabled && (
            <div style={{ margin: '1rem 0 0 0' }}>
              <Collapsable
                isOpen={false}
                style={{ marginTop: '1rem', marginLeft: '-.5rem' }}
                title={`(${thisRep.name}): ${currentPoi.name} ${
                  props.mediaOptions.hasAudioTour
                    ? `[${currentPoi.sortorder}]`
                    : ''
                }`}
              >
                <div style={{ margin: '1rem' }}>
                  <FormInput
                    id="representation_id"
                    type={FIELDTYPE.SELECT}
                    label="Representation"
                    isDisabled={isDisabled}
                    isPublished={true}
                    isLocked={false}
                    value={currentPoi.representation_id}
                    onChange={onChange}
                    options={{ selectionOptions: repOptions }}
                  />
                  {buildRepOptionForm()}
                </div>
              </Collapsable>
              <hr />
            </div>
          )}
          <div>
            <div style={{ marginTop: '0.8rem' }}>
              <FormInput
                id="name"
                type={FIELDTYPE.TEXT}
                label="Name"
                isPublished={true}
                value={currentPoi.name}
                isDisabled={isDisabled}
                onChange={onChange}
              />
            </div>
            <div style={{ marginTop: '0.8rem' }}>
              <FormInput
                id="date"
                type={FIELDTYPE.DATE}
                label={'Date'}
                isPublished={true}
                value={currentPoi.date}
                isDisabled={isDisabled}
                onChange={onChange}
              />
            </div>
          </div>
          <div style={{ marginTop: '0.8rem' }}>
            <FormInput
              id="coordinate"
              type={FIELDTYPE.COORDINATE}
              label="Coordinate"
              isPublished={true}
              value={currentPoi.coordinate}
              isDisabled={isDisabled}
              onChange={onChange}
            />
          </div>

          <div>
            <hr />

            {props.poiFields.map((field) => {
              let currentData = currentPoi.data.find(
                (item) => item.dataType === field.id
              );
              let fieldData = {
                search_scope: field.search_scope,
                is_published: field.is_published_by_default,
                is_locked: field.is_locked_by_default,
                is_markdown: field.is_markdown_by_default,
                dataType: field.id,
                poiId: poi.id,
                field: field.name,
                ...currentData
              };

              const selectionOptions =
                typeof props.poiSelectionOptions !== 'undefined'
                  ? props.poiSelectionOptions[field.id]
                  : [];
              return (
                <div style={{ marginTop: '0.8rem' }} key={field.id}>
                  <FormInput
                    hasOptionToggles={true}
                    id={field.id}
                    type={field.const}
                    label={field.name}
                    isMarkdown={fieldData?.is_markdown}
                    isPublished={fieldData?.is_published}
                    isLocked={fieldData?.is_locked}
                    isDisabled={isDisabled}
                    value={fieldData?.value}
                    onChange={onChange}
                    options={{
                      data: fieldData,
                      onTagClick: props.onTagClick,
                      mediaOptions: props.mediaOptions,
                      onLoadMediaLibrary: (opt) => {
                        props.onLoadMediaLibrary(opt, (item) => {
                          onChange({
                            target: {
                              dataset: { id: field.id },
                              value: item ? item.id : null
                            }
                          });
                        });
                      },
                      selectionOptions
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
export default PoiInspector;

PoiInspector.defaultProps = {};

PoiInspector.propTypes = {
  onLoadMediaLibrary: PropTypes.func,
  filteredPOI: PropTypes.array,
  propsMediaLibrary: PropTypes.object,
  mediaOptions: PropTypes.object,
  search: PropTypes.object,
  timeFilterVisible: PropTypes.bool,
  isAuthenticated: PropTypes.bool.isRequired,
  mapID: PropTypes.number,
  onChangeVisibility: PropTypes.func,
  onNavigate: PropTypes.func,
  onSearch: PropTypes.func,
  onCreateCustom: PropTypes.func,
  onTagClick: PropTypes.func,
  poiFields: PropTypes.array,
  poiRepresentationOptions: PropTypes.array,
  poiSelectionOptions: PropTypes.array,
  token: PropTypes.string
};
