import { IconButton, MediaLibrary, MediaPlayer } from 'components';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import defaultStyle from './defaultStyle.js';
import { useSelector } from 'react-redux';
import { makeAssetURL } from 'utility';

const MediaPicker = (props) => {
  const availableMedia = useSelector((redux) => redux.media.available.media);
  const styles = { ...defaultStyle, ...props.style };
  const media = availableMedia.find((item) => item.id == props.value);
  const [mediaLibraryOpen, setMediaLibraryOpen] = useState(false);
  const onToggleMediaLibrary = (e) => {
    setMediaLibraryOpen(!mediaLibraryOpen);
    /*
    console.log({
      showTypes: props.options.showTypes
        ? props.options.showTypes
        : ['video', 'audio', 'custom'],
      type: e.target.dataset,
      selectedID: e.target.dataset.mediaid,
      'data-uniqueid': props.uniqueID,
      'data-typeid': props.dataTypeID,
      'data-id': props.dataID,
      'data-isPublished': props.isPublished ? 'true' : 'false',
      'data-meta': JSON.stringify(props.metaData)
    });*/
  };
  const mediaPreview = media
    ? media.url.includes('http')
      ? makeAssetURL(media.preview)
        ? media.preview
        : 'missing'
      : makeAssetURL(media.url)
    : 'missing';
  return (
    <div style={props.isPublished ? null : styles.private}>
      {mediaLibraryOpen && (
        <MediaLibrary
          onOutsideClick={onToggleMediaLibrary}
          onSelectItem={(e) =>
            props.onChange({ id: props.id, value: e.selected })
          }
          selectOnly={true}
          showTypes={[]}
          selectedMediaId={media?.id}
        />
      )}
      <div style={{ flexGrow: 1 }}>
        {props.label === null ? '' : props.label}
      </div>
      <div
        style={
          props.isDisabled ? styles.mediaPicker_disabled : styles.mediaPicker
        }
      >
        {!props.isDisabled && (
          <IconButton
            style={{ position: 'relative', margin: '.5rem' }}
            icon={media ? 'pen' : 'plus'}
            dataset={{
              'data-type': props.type,
              'data-mediaid': media ? media.id : null
            }}
            onClick={onToggleMediaLibrary}
          />
        )}
        {media && (
          <div>
            <div>{media.name}</div>
            <div>
              <MediaPlayer media={media} />
              <img style={{ width: '100%' }} src={mediaPreview}></img>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

MediaPicker.defaultProps = {
  onChange: () => {}
};

MediaPicker.propTypes = {
  dataID: PropTypes.any,
  dataTypeID: PropTypes.any,
  id: PropTypes.any,
  isDisabled: PropTypes.bool,
  isLocked: PropTypes.bool,
  isPublished: PropTypes.any,
  label: PropTypes.string,
  metaData: PropTypes.any,
  onChange: PropTypes.func,
  options: PropTypes.object,
  style: PropTypes.any,
  type: PropTypes.any,
  uniqueID: PropTypes.any,
  value: PropTypes.any
};

export default MediaPicker;
