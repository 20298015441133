import * as actions from 'state/actions';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './User.module.css';
import WatchForOutsideClick from '../WatchForOutsideClick';
import Login from './Login';
import Manage from './Manage';
import { connect } from 'react-redux';
import CONST from 'constants.js';

class User extends Component {
  render() {
    return (
      <div className={[styles.wrapper, 'userManager'].join(' ')}>
        <div className={styles.container}>
          <WatchForOutsideClick
            onOutsideClick={() =>
              this.props.dispatch(actions.uiHide(CONST.UI.USER_SELF_MANAGER))
            }
          >
            {(this.props.userState.isAuthenticated && (
              <Manage
                onChangePassword={this.onChangePassword}
                onUpdateUser={this.onUpdateUser}
                onDataUpload={this.props.onDataUpload}
                isAuthenticated={this.props.userState.isAuthenticated}
                userInfo={this.props.userState}
                onLogout={() => this.props.dispatch(actions.logout())}
              />
            )) || (
              <Login
                onLogin={(username, password) => {
                  this.props.dispatch(
                    actions.login({
                      username,
                      password,
                      mapid: this.props.appState.id
                    })
                  );
                  if (document.querySelectorAll('.userManager').length > 0) {
                    document
                      .querySelectorAll('.userManager')[0]
                      .classList.remove(styles.shakeMe);
                    this.timer_shakeEffect = setTimeout(() => {
                      document
                        .querySelectorAll('.userManager')[0]
                        .classList.add(styles.shakeMe);
                      setTimeout(() => {
                        document
                          .querySelectorAll('.userManager')[0]
                          .classList.remove(styles.shakeMe);
                      }, 500);
                    }, 500);
                  }
                }}
                onForgotPassword={(id) => {
                  window.alertBar(
                    `Account has been reset, please check the email for '${id}' to log in.`
                  );
                  this.props.dispatch(actions.userPassReset({ id }));
                }}
              />
            )}
          </WatchForOutsideClick>
        </div>
      </div>
    );
  }

  onChangePassword = (payload, opt) => {
    this.props.dispatch(actions.updateUserWithPasswordCheck({ payload, opt }));
  };

  onUpdateUser = (payload, opt) => {
    this.props.dispatch(actions.updateUser({ payload, opt }));
  };
}

export default connect((reduxState) => {
  return {
    userState: reduxState.user,
    appState: reduxState.app
  };
})(User);

User.propTypes = {
  userState: PropTypes.object,
  appState: PropTypes.object,
  dispatch: PropTypes.func,
  onDataUpload: PropTypes.func,
  isAuthenticated: PropTypes.bool,
  onOutsideClick: PropTypes.func,
  userInfo: PropTypes.object,
  onLogin: PropTypes.func,
  onLogout: PropTypes.func,
  onForgotPassword: PropTypes.func
};
