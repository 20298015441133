import initialState from './init/media';
import * as actions from 'state/actions';
import common from './common.js';

export default function app(state = initialState, action) {
  switch (action.type) {
    case actions.MEDIA_GET_CONFIG_RESPONSE: {
      if (typeof action.payload.response.code !== 'undefined') {
        return { ...state };
      } else {
        let media;
        if (action.payload.response[0]?.available_media) {
          media = [...action.payload.response[0]?.available_media];
          if (media) media.sort((a, b) => a.name.localeCompare(b.name));
        }
        return {
          ...state,
          available: {
            media,
            triggers: action.payload.response[0]?.available_triggers,
            transitions: action.payload.response[0]?.available_transitions
          }
        };
      }
    }
    case actions.MEDIA_GET_RESPONSE: {
      return {
        ...state,
        available: {
          ...state.available,
          media: action.payload.response
        }
      };
    }
    case actions.MEDIA_UPDATE_ENQUEUE: {
      const needsUpdate = [...state.needsUpdate];
      needsUpdate.push(action.payload);
      return {
        ...state,
        available: {
          ...state.available,
          media: common.updateInArrayById(state.available.media, action.payload)
        },
        needsUpdate
      };
    }
    case actions.MEDIA_CLEAR_UPDATE_QUEUE: {
      return {
        ...state,
        needsUpdate: []
      };
    }
    default:
      return state;
  }
}
