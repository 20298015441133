import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import { makeAssetURL } from 'utility';
import { ImagePreview } from 'components';

const MediaPlayer = (props) => {
  debugger;
  if (props.media.type.includes('image')) {
    return (
      <ImagePreview
        exif={props.media.exif}
        id={props.media.id}
        src={makeAssetURL(props.media.url)}
      />
    );
  } else {
    if (props.media.url.length === 0)
      return (
        <ImagePreview
          exif={props.media.exif}
          id={props.media.id}
          src="icons/missing.png"
        />
      );
    return (
      <ReactPlayer
        height={props.media.type.includes('audio') ? '2rem' : '100%'}
        width="100%"
        url={makeAssetURL(props.media.url)}
        controls
      />
    );
  }
};

const styles = {
  container: {
    margin: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: '5rem',
    height: '100%',
    lineHeight: '1rem'
  },
  editor: {
    flexGrow: 1,
    textAlign: 'left',
    minWidth: '10rem',
    maxWidth: '10rem',
    padding: '.5rem',
    marginRight: '1rem',
    overflow: 'hidden'
  },
  player: {
    flexGrow: 1,
    overflow: 'hidden',
    textAlign: 'center',
    minWidth: '10rem',
    padding: '.2rem',
    maxHeight: '11rem'
  },
  preview: {
    flexGrow: 1,
    textAlign: 'center',
    minWidth: '11rem',
    maxWidth: '11rem'
  }
};

export default MediaPlayer;
