/*
  These are the preferences, each is an object following the same pattern as rep options and other form items.

  - This is the order that the params will be displayed.
  - The fields in the objects are hints for how the UI is built
  - label: * will cause the field to exist with the default value, but not appear in the form.
  - If a param is not in this list, it will not be stored.
  - If a param is on this list it WILL be stored (so you can add things here and they will be subsequently stored in the DB)
  - isSharable indicates that the pref can be shared across multiple maps in the same system (multi pages, for instance)

*/
import { FIELDTYPE } from 'components/FormInput';
import { arrayOfNumberOptionsFrom } from 'utility';
import uuidv4 from 'uuid/v4';
export default {
  title: {
    label: 'Title',
    isShareable: false,
    default: 'The Map',
    formType: FIELDTYPE.TEXT,
    validation: 'string',
    tooltip: 'The title of the map. Displayed on the navigation bar.'
  },
  uuid: {
    label: 'Package ID',
    isShareable: false,
    default: uuidv4(),
    formType: FIELDTYPE.TEXT,
    validation: 'string',
    tooltip: 'The external id of the map, used by external software'
  },
  isPublished: {
    label: 'Published?',
    isShareable: true,
    default: true,
    formType: FIELDTYPE.CHECKBOX,
    validation: 'boolean',
    tooltip: 'Is the map visible without login?'
  },
  hasNavigation: {
    label: 'Navigation',
    requiresConfirmation:
      'Are you sure you want to disable all navigation? If you do you will not be able to access this menu or any functionality except via the console.',
    isShareable: true,
    default: true,
    formType: FIELDTYPE.CHECKBOX,
    validation: 'boolean',
    tooltip:
      'Enables navigation bar and related functionality. If this is turned off, you will need to use admin function on the console to re-enable.'
  },
  hasGeoSearch: {
    label: 'Geosearch',
    isShareable: true,
    default: true,
    formType: FIELDTYPE.CHECKBOX,
    validation: 'boolean',
    tooltip:
      'Displays the geosearch option, requires properly configured google API key'
  },
  hasHome: {
    label: 'Home',
    isShareable: true,
    default: true,
    formType: FIELDTYPE.CHECKBOX,
    validation: 'boolean',
    tooltip:
      'Display a home button on the navigation bar, which resets the map to inital view and zoom when pressed.'
  },
  hasInfo: {
    label: 'Info',
    isShareable: true,
    default: true,
    formType: FIELDTYPE.CHECKBOX,
    validation: 'boolean',
    tooltip:
      'Display an info button which launches a popup containing the URL set below in the Info URL field'
  },
  hasLock: {
    label: 'Lock',
    isShareable: true,
    default: true,
    formType: FIELDTYPE.CHECKBOX,
    validation: 'boolean',
    tooltip:
      'Displays a button on the navigation bar which allows the user to toggle the map lock'
  },
  hasPagination: {
    label: 'Pagination controls?',
    isShareable: true,
    default: false,
    formType: FIELDTYPE.CHECKBOX,
    validation: 'boolean',
    tooltip:
      'Provide a page turning style UI for moving between maps, mostly for use as a document viewer'
  },
  hasSearch: {
    label: 'Search',
    isShareable: true,
    default: true,
    formType: FIELDTYPE.CHECKBOX,
    validation: 'boolean',
    tooltip:
      'Displays a search icon on the navigation bar which allows the user to search points of interest.'
  },
  hasTimeFilter: {
    label: 'Time Filter',
    isShareable: true,
    default: true,
    formType: FIELDTYPE.CHECKBOX,
    validation: 'boolean',
    tooltip: 'Adds time-filter functionality to the search'
  },
  hasUserLocate: {
    label: 'Show My Location',
    isShareable: true,
    default: true,
    formType: FIELDTYPE.CHECKBOX,
    validation: 'boolean',
    tooltip:
      'Adds a "show my location" button to the map, click once to locate yourself, click again to lock the map to that location (useful on mobile)'
  },
  hasUserManager: {
    label: 'User Login',
    isShareable: true,
    default: true,
    formType: FIELDTYPE.CHECKBOX,
    validation: 'boolean',
    tooltip:
      'Adds a user icon to the navigation bar and enables user-management capability'
  },
  hasMapControls: {
    label: 'Map: Show controls',
    isShareable: true,
    default: true,
    formType: FIELDTYPE.CHECKBOX,
    validation: 'boolean',
    tooltip:
      'Shows the map controls (zoom/tilt) as buttons in the lower right of the map'
  },
  allowRotation: {
    label: 'Map: allow rotation',
    isShareable: true,
    default: true,
    formType: FIELDTYPE.CHECKBOX,
    validation: 'boolean',
    tooltip: 'Allow the map to be rotated and tilted'
  },
  useFly: {
    label: 'Use fly animation?',
    isShareable: true,
    default: true,
    formType: FIELDTYPE.CHECKBOX,
    validation: 'boolean',
    tooltip:
      'Use the flyto animation on map playback, otherwise will snap to the locations. Note that flying is much smoother with vector than raster tiles.'
  },
  hasMediaLibrary: {
    label: 'Media Support?',
    isShareable: true,
    default: false,
    formType: FIELDTYPE.CHECKBOX,
    validation: 'boolean',
    tooltip:
      'Enables media support, requires properly configured datastore (S3)'
  },
  hasAudioTour: {
    label: 'Support Audio Tour?',
    isShareable: true,
    default: false,
    formType: FIELDTYPE.CHECKBOX,
    validation: 'boolean',
    tooltip:
      'Enables audio tour support, requires properly configured media support to function'
  },
  promptToAddToHomescreen: {
    label: 'Prompt to add?',
    isShareable: true,
    default: false,
    formType: FIELDTYPE.CHECKBOX,
    validation: 'boolean',
    tooltip: 'Prompts the user to add this as an app to their homescreen.'
  },
  restrictToBounds: {
    label: 'Constrain to bounds?',
    isShareable: true,
    default: false,
    formType: FIELDTYPE.CHECKBOX,
    validation: 'boolean',
    tooltip:
      'Works in conjunction with the view specified in "set initial view." If checked, the user will not be able to move the map outside the bounds'
  },
  autoBounds: {
    label: 'Auto bounds on startup?',
    isShareable: true,
    default: false,
    formType: FIELDTYPE.CHECKBOX,
    validation: 'boolean',
    tooltip: ''
  },
  hasAutoBoundsButton: {
    label: 'Autobound button?',
    isShareable: true,
    default: false,
    formType: FIELDTYPE.CHECKBOX,
    validation: 'boolean',
    tooltip: ''
  },
  userLocateStartDirectly: {
    label: 'Locate user on startup?',
    isShareable: true,
    default: false,
    formType: FIELDTYPE.CHECKBOX,
    validation: 'boolean',
    tooltip:
      'Requires user location to be turned on. If this option is selected the map will immediately request user location on load.'
  },
  url_info: {
    label: 'Info URL',
    isShareable: true,
    default: '',
    formType: FIELDTYPE.TEXT,
    validation: 'url',
    tooltip:
      'URL to a simple HTML file which is displayed in the info box. This URL must properly support CORS (check the console to troubleshoot)'
  },
  numberOfTracks: {
    label: 'Number of tracks',
    isShareable: true,
    default: 3,
    formType: FIELDTYPE.TEXT,
    validation: 'integer',
    tooltip: 'Number of tracks for the audiotour'
  },
  poi_inspector: {
    label: 'Default POI Display:',
    selectionOptions: [
      { id: 'default', name: 'default' },
      { id: 'media', name: 'media' }
    ],
    isShareable: true,
    default: 'default',
    formType: FIELDTYPE.SELECT,
    validation: '',
    tooltip: 'The default display for POI when you are NOT logged in.'
  },
  infoDisplayAtLaunch: {
    label: '*',
    isShareable: false,
    default: false,
    formType: FIELDTYPE.CHECKBOX,
    validation: 'boolean',
    tooltip:
      'Display the info box at startup, allow the user to select "do not show again"'
  },
  is_published: {
    label: '*',
    isShareable: false,
    default: false,
    formType: FIELDTYPE.CHECKBOX,
    validation: 'boolean',
    tooltip: 'Not currently supported - Map available without login?'
  },
  map_bg_color: {
    label: 'Map Background Color',
    isShareable: true,
    default: 'FFFFFF',
    formType: FIELDTYPE.COLOR,
    validation: 'color',
    tooltip: 'Background color in hex',
    requiresMapRefresh: true
  },
  map_tileserverId: {
    label: 'Tileserver',
    isShareable: true,
    default: 1,
    formType: FIELDTYPE.TILESELECT,
    validation: 'integer',
    tooltip: '',
    requiresMapRefresh: true
  },
  map_zoom_min: {
    label: 'Min Zoom:',
    selectionOptions: [...Array(25).keys()].map((option) => {
      return { id: option, name: option };
    }),
    isShareable: true,
    default: 0,
    formType: FIELDTYPE.SELECT,
    validation: 'float',
    tooltip: ''
  },
  map_zoom_max: {
    label: 'Max Zoom:',
    selectionOptions: arrayOfNumberOptionsFrom(0, 24),
    isShareable: true,
    default: 20,
    formType: FIELDTYPE.SELECT,
    validation: 'float',
    tooltip: ''
  },
  map_zoom_poi: {
    label: 'POI Zoom:', //Map Initial Zoom
    selectionOptions: arrayOfNumberOptionsFrom(0, 24),
    isShareable: true,
    default: 5,
    formType: FIELDTYPE.SELECT,
    validation: 'float',
    tooltip: ''
  },
  media_max_size: {
    label: 'Media Max Size (blank for no limit)',
    isShareable: false,
    default: null,
    formType: FIELDTYPE.TEXT,
    validation: 'integer',
    tooltip: 'Maximum supported media file size in bytes'
  },
  media_supported_types: {
    label: 'Supported Media (use mimetypes)',
    isShareable: true,
    default: 'image/png, image/jpeg',
    tooltip: 'Mimetypes of supported media'
  },
  map_zoom_initial: {
    label: '*', //Map Initial Zoom
    selectionOptions: arrayOfNumberOptionsFrom(0, 24),
    isShareable: true,
    default: 5,
    formType: FIELDTYPE.SELECT,
    validation: 'float',
    tooltip: ''
  },
  map_initial_position: {
    label: '*',
    isShareable: true,
    default: { lat: 42.22454459665412, lng: -70.37137985229492 },
    formType: FIELDTYPE.COORDINATE,
    validation: 'coordinate',
    tooltip: ''
  },
  map_initial_bounds: {
    label: '*',
    isShareable: true,
    default: {
      _southWest: { lat: 32.663511957909556, lng: -112.55887985229492 },
      _northEast: { lat: 51.785577235398684, lng: -28.183879852294925 }
    },
    formType: FIELDTYPE.BOUNDS,
    validation: 'bounding',
    tooltip: ''
  }
};
