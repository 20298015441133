import {
  takeLatest,
  takeEvery,
  all,
  debounce,
  put,
  call
} from 'redux-saga/effects';
import * as actions from 'state/actions';
import * as app from './app.js';
import * as poi from './poi.js';
import * as proximity from './proximity.js';
import * as user from './user.js';
import * as media from './media.js';
import * as audiotour from './audiotour.js';
import * as s3 from './s3.js';
import * as server from './server.js';
import * as location from './location.js';
import * as api from './api.js';
import * as packager from './packager.js';

export default function* rootSaga() {
  yield all([
    app.watchBridge(),
    yield debounce(500, actions.APP_SYNCH_END, function* () {
      yield put({ type: actions.APP_SYNCH_END_DEBOUNCED });
    }),

    takeLatest(actions.APP_UPDATE_PREFS, function* (action) {
      yield put({
        type: actions.APP_UPDATE_PREFS_LIVE,
        payload: action.payload
      });
      yield call(app.recordPrefChanges, action.payload);
    }),

    takeLatest(actions.UI_SHOW, app.uiShow),
    takeLatest(actions.APP_UPDATE_GLOBAL_PREFS, app.recordGlobalPrefs),

    takeLatest(actions.POI_GET, poi.getPOI),
    takeLatest(actions.POI_REFRESH, poi.refreshPoi),
    takeLatest(actions.POI_SET_DEFAULTSTYLE, poi.setDefaultStyle),
    takeLatest(actions.POI_RESEQUENCE, poi.resequencePoi),
    takeLatest(actions.POI_GET_SELECTION_OPTIONS, poi.getSelectionOptions),
    takeLatest(actions.POI_UPDATE, function* (action) {
      yield put({ type: actions.POI_UPDATE_ENQUEUE, payload: action.payload });
      yield call(poi.recordChanges, action.payload);
    }),
    takeLatest(actions.POI_CREATE, function* (action) {
      yield put({ type: actions.POI_CREATE_ENQUEUE, payload: action.payload });
      yield call(poi.recordChanges, action.payload);
    }),
    takeLatest(actions.POI_DELETE, function* (action) {
      yield put({ type: actions.POI_DELETE_ENQUEUE, payload: action.payload });
      yield call(poi.recordChanges, action.payload);
    }),

    takeLatest(actions.PROXIMITY_GET, proximity.getProximity),
    takeLatest(actions.PROXIMITY_REFRESH, proximity.refreshProximity),
    takeLatest(actions.PROXIMITY_RESEQUENCE, proximity.resequenceProximity),
    takeLatest(actions.PROXIMITY_UPDATE, function* (action) {
      yield put({
        type: actions.PROXIMITY_UPDATE_ENQUEUE,
        payload: action.payload
      });
      yield call(proximity.recordChanges, action.payload);
    }),
    takeLatest(actions.PROXIMITY_CREATE, function* (action) {
      yield put({
        type: actions.PROXIMITY_CREATE_ENQUEUE,
        payload: action.payload
      });
      yield call(proximity.recordChanges, action.payload);
    }),
    takeLatest(actions.PROXIMITY_DELETE, function* (action) {
      yield put({
        type: actions.PROXIMITY_DELETE_ENQUEUE,
        payload: action.payload
      });
      yield call(proximity.recordChanges, action.payload);
    }),

    takeLatest(actions.API_REFRESH_MAP, api.refreshMapIfNeeded),
    api.initCrudRequestQueue(),

    takeLatest(actions.APP_INITIALIZATION_RECEIVED, poi.getPOI),
    takeLatest(actions.APP_INITIALIZATION_RECEIVED, proximity.getProximity),
    takeLatest(
      actions.APP_INITIALIZATION_RECEIVED,
      user.checkAuthorizationStatus
    ),
    takeLatest(actions.APP_INITIALIZE, app.initialize),
    takeLatest(actions.APP_LOGIN, user.login),
    takeLatest(actions.APP_LOGIN_RESPONSE, user.login_resp),
    takeLatest(actions.APP_LOGIN_RESPONSE, audiotour.getTourItems),
    takeLatest(actions.APP_LOGIN_RESPONSE, packager.getManifest),
    takeLatest(actions.APP_LOGOUT, poi.getPOI),
    takeLatest(actions.APP_LOGOUT, user.logout),
    takeLatest(actions.APP_PIN_UNPIN_ALL, app.unpinAll),
    takeLatest(actions.APP_REAUTHORIZE, user.reauthorize),

    takeLatest(actions.APP_VISIBILITY_ALL_HIDDEN, app.hideAll),

    takeLatest(actions.APP_EXPORT_STANDALONE, api.exportStandalone),

    takeLatest(actions.AUDIOTOUR_RECORD_CHANGES, audiotour.recordChanges),
    takeLatest(actions.AUDIOTOUR_RESEQUENCE, audiotour.resequenceTrackItems),
    takeLatest(actions.AUDIOTOUR_CREATE_ITEM, function* (action) {
      yield put({
        type: actions.AUDIOTOUR_CREATE_ENQUEUE,
        payload: action.payload
      });
      yield call(audiotour.recordChanges, action.payload);
    }),
    takeLatest(actions.AUDIOTOUR_UPDATE_ITEM, function* (action) {
      yield put({
        type: actions.AUDIOTOUR_UPDATE_ENQUEUE,
        payload: action.payload
      });
      yield call(audiotour.recordChanges, action.payload);
    }),
    takeLatest(actions.AUDIOTOUR_UPDATE_ITEM_GROUP, audiotour.updateTrackGroup),
    takeLatest(actions.AUDIOTOUR_DELETE_ITEM, function* (action) {
      yield put({
        type: actions.AUDIOTOUR_DELETE_ENQUEUE,
        payload: action.payload
      });
      yield call(audiotour.recordChanges, action.payload);
    }),

    takeLatest(actions.APP_ENABLE_DRAWING, app.enableMapDraw),
    takeLatest(actions.APP_DISABLE_DRAWING, app.disableMapDraw),

    takeLatest(actions.AUDIOTOUR_GET_CONFIG, audiotour.getTourConfig),
    takeLatest(actions.AUDIOTOUR_GET_ITEMS, audiotour.getTourItems),
    takeLatest(actions.AUDIOTOUR_GET_ITEMS, audiotour.getTourConfig),
    takeLatest(actions.AUDIOTOUR_CRUD_RESPONSE, audiotour.crud_response),
    takeLatest(actions.AUDIOTOUR_UPDATE_WEATHER, audiotour.updateWeather),
    takeLatest(actions.AUDIOTOUR_UPDATE_CONFIG, function* (action) {
      yield put({
        type: actions.AUDIOTOUR_UPDATE_CONFIG_ENQUEUE,
        payload: action.payload
      });
      yield call(audiotour.recordConfigChanges, action.payload);
      if (action.payload.e.id === 'bellwether_poi') {
        yield put({
          type: actions.AUDIOTOUR_UPDATE_WEATHER,
          payload: {
            mapId: action.payload.mapId,
            poi: action.payload.e.data.item
          }
        });
      }
    }),

    takeLatest(actions.APP_SET_UI_MODE, app.enableMapDraw),
    takeLatest(actions.APP_SET_UI_MODE, app.disableMapDraw),

    takeLatest(actions.LOCATION_DISABLE, location.disable),
    takeLatest(actions.LOCATION_ENABLE, location.enable),

    takeLatest(actions.MEDIA_GET, media.getMedia),
    takeLatest(actions.MEDIA_GET_CONFIG, media.getMediaConfig),
    takeLatest(actions.MEDIA_CREATE, media.createMedia),
    takeLatest(actions.MEDIA_REPLACE, media.replaceMedia),
    takeLatest(actions.MEDIA_DELETE, media.deleteMedia),
    takeLatest(actions.MEDIA_UPDATE, function* (action) {
      yield put({
        type: actions.MEDIA_UPDATE_ENQUEUE,
        payload: action.payload
      });
      yield call(media.recordMediaChanges, action.payload);
    }),
    takeLatest(actions.MEDIA_DELETE_PREVIEW, media.deleteMediaPreview),

    takeLatest(actions.SERVER_CREATE, server.packageCreate),
    takeLatest(actions.SERVER_CREATE_RESPONSE, server.packageCreate_response),
    takeLatest(actions.SERVER_DELETE, server.packageDelete),
    takeLatest(actions.SERVER_DELETE_RESPONSE, server.packageDelete_response),
    takeLatest(actions.SERVER_GET_MANIFEST, server.getManifest),
    takeLatest(
      actions.SERVER_GET_MANIFEST_RESPONSE,
      server.getManifest_response
    ),
    takeLatest(actions.SERVER_INIT, server.initPackage),
    takeLatest(actions.SERVER_SET_MANIFEST, server.setManifest),
    takeLatest(actions.SERVER_STATUS, server.packageStatus),
    takeLatest(actions.SERVER_STATUS_RESPONSE, server.packageStatus_response),

    takeLatest(actions.SERVER_S3_GET_SIGNED_UPLOAD, s3.signUpload),
    takeLatest(
      actions.SERVER_S3_GET_SIGNED_UPLOAD_RESPONSE,
      s3.signUpload_response
    ),
    takeLatest(actions.SERVER_S3_FILE_CREATE, s3.fileCreate),
    takeLatest(actions.SERVER_S3_FILE_DELETE, s3.fileDelete),
    takeLatest(actions.SERVER_S3_FILE_DELETE_RESPONSE, s3.fileDelete_response),

    takeLatest(actions.APP_SAGA_ERROR, app.sagaError),
    takeLatest(actions.APP_SAGA_ERROR_NONFATAL, app.sagaErrorNonfatal),

    takeLatest(actions.USER_PASS_RESET, user.resetPassword),
    takeLatest(actions.USER_CREATE, user.createUser),
    takeLatest(actions.USER_DELETE, user.deleteUser),
    takeLatest(actions.USER_GET, user.getUsers),
    takeLatest(actions.USER_REQUEST_VALIDATION, user.requestValidation),
    takeLatest(actions.USER_UPDATE, user.updateUser),
    takeLatest(
      actions.USER_UPDATE_WITH_CHECK,
      user.updateUserWithPasswordCheck
    ),
    takeLatest(actions._USER_CRUD_RESPONSE, user.crud_response),
    takeLatest(actions._USER_CREATE, (action) =>
      user.crud_helper('POST', 'users', action)
    ),
    takeLatest(actions._USER_DELETE, (action) =>
      user.crud_helper('DELETE', 'users', action)
    ),
    takeLatest(actions._USER_UPDATE, (action) =>
      user.crud_helper('PATCH', 'users', action)
    ),
    takeLatest(actions.PKG_CREATE, packager.createPackage),
    takeLatest(actions.PKG_DELETE, packager.deletePackage),
    takeLatest(actions.PKG_GET_MANIFEST, packager.getManifest),
    takeEvery(actions.PKG_GET_STATUS, packager.getStatus),
    takeEvery(actions.PKG_GET_STATUS_RESPONSE, packager.getStatus_r)
  ]);
}
