import React from 'react';
import PropTypes from 'prop-types';
import { WatchForOutsideClick } from 'components';
import { makeAssetURL, detectEnvironment } from 'utility';
import ReactPlayer from 'react-player';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from 'state/actions';
import CONST from 'constants.js';
import colors from 'colors';

export const MediaDisplay = () => {
  const dispatch = useDispatch();
  const availableMedia = useSelector((redux) => redux.media.available.media);
  const currentPoi = useSelector((redux) =>
    redux.poi.all?.find((item) => item.id === redux.poi.current?.id)
  );
  if (!currentPoi) return null;
  const onLaunchYoutube = (e) => {
    const url =
      e.currentTarget.dataset.environment === CONST.PLATFORM.IOS
        ? `vnd.youtube://www.youtube.com/watch?v=${e.currentTarget.dataset.youtubeid}`
        : `vnd.youtube:${e.currentTarget.dataset.youtubeid}`;
    window.open(url);
  };

  let player;
  currentPoi.data.forEach((datum) => {
    if (datum.field.toLowerCase() === 'media') {
      const media = availableMedia.find(
        (item) => item.id === parseInt(datum.value, 10)
      );

      const isAudio =
        (media && media.type.includes('audio')) ||
        (media &&
          media.url &&
          ['wav', 'mp3'].includes(media.url.split('.').pop()));

      switch (detectEnvironment()) {
        case CONST.PLATFORM.IOS:
        case CONST.PLATFORM.ANDROID: {
          player = (
            <div
              data-environment={detectEnvironment()}
              data-youtubeID={
                media.url.split('/')[media.url.split('/').length - 1]
              }
              style={styles.youtubeLauncher}
              onClick={onLaunchYoutube}
            >
              <div>
                <div style={styles.previewImage}>
                  <img
                    alt="click to launch"
                    src={makeAssetURL(media.preview)}
                  />
                </div>
                <div style={styles.caption}>Click to Launch 360° Viewer</div>
              </div>
            </div>
          );
          break;
        }
        default:
        case CONST.PLATFORM.STANDARD:
          {
            let playersettings = isAudio
              ? {
                  playing: true,
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  url: makeAssetURL(media.url),
                  controls: true
                }
              : {
                  playing: true,
                  height: '50rem',
                  width: '100%',
                  url: media ? makeAssetURL(media.url) : '',
                  controls: true
                };
            player = <ReactPlayer {...playersettings} />;
          }
          break;
      }
    }
  });
  return (
    <div style={styles.outerContainer}>
      <WatchForOutsideClick
        onOutsideClick={() =>
          dispatch(actions.uiHide(CONST.UI.MEDIA_INSPECTOR))
        }
      >
        <div style={styles.innerContainer}>
          <div style={styles.title}>{currentPoi.name}</div>
          <div>{player}</div>
        </div>
      </WatchForOutsideClick>
    </div>
  );
};
export default MediaDisplay;

const styles = {
  outerContainer: {
    zIndex: 5,
    position: 'fixed',
    top: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100vw',
    backgroundColor: colors.modalBackground
  },
  innerContainer: {
    zIndex: 5,
    borderRadius: '0.3rem',
    display: 'flex',
    flexDirection: 'column',
    width: '90vw',
    minWidth: '20rem',
    backgroundColor: 'black',
    boxShadow: colors.shadow,
    overflow: 'scroll'
  },
  container: { height: '100%' },
  title: {
    fontSize: '1.5rem',
    fontWeight: '900',
    padding: '1rem',
    backgroundColor: '#cccccc'
  },
  youtubeLauncher: {
    backgroundColor: '#cccccc',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    bottom: '0',
    padding: '1rem'
  },
  caption: { textAlign: 'center', margin: '1rem 0 0 0', fontSize: '1.5rem' },
  previewImage: {
    border: '1px solid black',
    height: '10rem',
    width: '10rem',
    borderRadius: '10rem',
    margin: 'auto',
    overflow: 'hidden'
  },
  previewImage_img: { height: '10rem' }
};

MediaDisplay.defaultProps = {};
MediaDisplay.propTypes = {
  onOutsideClick: PropTypes.func
};
